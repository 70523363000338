import { ReactNode } from 'react';
import {
	DefaultOptions,
	Mutation,
	MutationCache,
	Query,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { useTypedDispatch } from '@/store/hooks';
import { setErrorCode, setLoading } from '@/store/slices/appSlice';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HttpStatusCode } from 'axios';
import APP_CONFIG from '@/configs/appConfig';

export const QueryProvider = ({ children }: { children: ReactNode }) => {
	const dispatch = useTypedDispatch();

	const handleQueryError = (
		error: any,
		query: Query<unknown, unknown, unknown>
	) => {
		if (import.meta.env.DEV) console.error(error);

		if (query.meta?.ignoreErrors) return;

		dispatch(
			setErrorCode(
				!isNaN(Number(error.message))
					? error.message
					: HttpStatusCode.InternalServerError.toString()
			)
		);
	};

	const handleMutationError = (
		error: any,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown>
	) => {
		if (import.meta.env.DEV) console.error(error);

		if (mutation.meta?.ignoreErrors) return;

		if (
			Number(error.message) >= HttpStatusCode.InternalServerError ||
			isNaN(Number(error.message))
		)
			dispatch(
				setErrorCode(
					!isNaN(Number(error.message))
						? error.message
						: HttpStatusCode.InternalServerError.toString()
				)
			);
	};

	const defaultOptions: DefaultOptions = {
		queries: {
			staleTime: APP_CONFIG.QUERY_STALE_TIME,
			gcTime: APP_CONFIG.QUERY_CACHE_TIME,
			retry: APP_CONFIG.QUERY_RETRY,
		},
		mutations: {
			retry: false,
		},
	};

	const queryCache = new QueryCache({
		onError: handleQueryError,
		onSuccess: (_, { meta }) => {
			if (meta?.persistLoading) return;
			dispatch(setLoading(false));
		},
	});

	const mutationCache = new MutationCache({
		onError: handleMutationError,
	});

	const queryClient = new QueryClient({
		defaultOptions,
		queryCache,
		mutationCache,
	});

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools buttonPosition="bottom-right" />
		</QueryClientProvider>
	);
};

import { QuestionnaireStatus } from '@/api/QuestionnaireApi';
import { AnswerTemplate } from '@/api/PreQuestionnaireApi';
import { Attachment, Gender, Language } from './types';
import { Client } from './user';
import { FileWithPath } from '@mantine/dropzone';

export interface Research {
	id: string;
	name: string;
	fullService: boolean;
	description: string;
	client?: Client;
	researchGroups: ResearchGroup[];
	researchToken: ResearchToken;
	contactPersons: ContactPerson[];
	researchAttachments: ResearchAttachment[];
	temporalAttachments: FileWithPath[];
	deletedAttachments: string[];
	updatedAttachments: { id: string; file: File }[];
	completedAmount?: number;
	status: ResearchStatus;
	sentAmount?: number;
	createdAt?: string;
	startedAt?: string;
	reStartedAt?: string;
	finishedAt?: string;
}

export interface ResearchGroupOptions {
	isAdditionalFeaturesApplied: boolean;
}

export interface ResearchGroup {
	id: string;
	show: boolean;
	isNew: boolean;
	isInvitedsLoaded?: boolean;
	isRepresentativeMode: boolean;
	name: string;
	mobile: boolean;
	desktop: boolean;
	cameraRequired: boolean;
	dataCollecting: boolean;
	isBooster: boolean;
	amountOfQuestionnaires: number;
	gender: Gender;
	regionSwitch: LocalizationTypes;
	regionsChosen: Region[];
	postalCodesChosen: string[];
	ageSpan: [number, number];
	startsAt: string;
	duration: number;
	loi: number;
	ir: number;
	profilesChosen: ProfileChosen[];
	questionsChosen: Question[];
	quantity: {
		gender: {
			isAuto: boolean;
			male: number;
			female: number;
		};
		region: {
			isAuto: boolean;
			regions: { id: string; region: Region; value: number }[];
			postalCodes: { code: string; value: number }[];
		};
		age: {
			isAuto: boolean;
			spans: { id: string; span: [number, number]; value: number }[];
		};
	};
	crossSwitches: CrossSwitches;
	crossing: Cross[];
	userCrosses: Cross[];
	invitedPanelists: string[];
	excludedPanelists: string[];
	omitId: boolean;
	isUniqueLinks: boolean;
	questionnaireUrl: string;
	testQuestionnaireUrl: string;
	uniqueLinks: string[];
	note: string;
	options: ResearchGroupOptions;
	panelistRegistrationDateFrom?: string;
}

export interface PriceValues {
	selfManaged: boolean;
	client?: string;
	values: {
		amountOfQuestionnaires: number;
		ir: number;
		loi: number;
		cameraRequired: boolean;
		dataCollecting: boolean;
	}[];
}

export interface Feasibility {
	ageFrom: number;
	ageTo: number;
	gender: Gender;
	answers: { id: string; content: string }[];
	postalCode?: string;
	city?: string;
	province?: string;
	county?: string;
	feasibility: number;
}

export interface Answer {
	id: string;
	value: number;
	translations: Record<Language, { content: string }>;
}

export interface Question {
	id: string;
	answers: Answer[];
	translations: Record<Language, { content: string; description: string }>;
}

export interface ProfileChosen {
	id: string;
	isAuto: boolean;
	color: string;
	questions: Question[];
	translations: {
		[key in Language]: { name: string };
	};
}

export interface Cross {
	id?: string;
	ageFrom: number;
	ageTo: number;
	gender: Gender;
	answers: { id: string; translations: AnswerTemplate['translations'] }[];
	amount: number;
	province: string | null;
	county: string | null;
	city: string | null;
	postalCode: string | null;
	feasibility?: number;
}

export interface CrossSwitches {
	gender: boolean;
	region: boolean;
	age: boolean;
	[key: string]: boolean;
}

export enum LocalizationTypes {
	COUNTRY = 1,
	REGION = 2,
	POSTAL_CODE = 3,
}

export interface ContactPerson {
	name: string;
	surname: string;
	email: string;
	phone: string;
}

export enum ResearchStatus {
	DRAFT,
	ACCEPTED,
	TESTING,
	SOFT_LAUNCH,
	FULL_LAUNCH,
	FINISHED,
}

export interface ListResearch
	extends Pick<
		Research,
		| 'id'
		| 'name'
		| 'client'
		| 'status'
		| 'completedAmount'
		| 'startedAt'
		| 'finishedAt'
		| 'fullService'
	> {
	researchGroups: Pick<ResearchGroup, 'amountOfQuestionnaires'>[];
}

export interface CalculatedPrice {
	total: number;
	discount: number;
	management: number;
	values: { pricePerOne: number; total: number }[];
}

export interface ResearchAttachment {
	id: string;
	attachment: Attachment | File;
	research: Research;
	createdAt: string;
	updatedAt: string;
}

export interface GroupStats {
	group: string;
	group_id: string;
	totalCount: number;
	started: number;
	finished: number;
	completed: number;
	quotafull: number;
	screenout: number;
	qual: number;
	dropout: number;
	timeout: number;
	cpi: number;
	initialCpi: number;
	loi_sum: number;
	declared_loi: number;
	real_ir: number;
	declared_ir: number;
}

export interface CrossStat {
	completed: number;
	declared: number;
	fulfilled: number;
	quotafull: number;
	screenout: number;
	qual: number;
	sent: number;
	title: string;
}

export interface ResearchGroupStatsUniqueUrl {
	link: string;
	panelistId: string;
	status: 'available' | 'used';
}

export interface ResearchGroupUrlStats {
	target: string;
	test_target: string;
	note: string;
	isUniqueLinks: boolean;
	uniqueLinks: ResearchGroupStatsUniqueUrl[];
}

export interface ResearchStats {
	questionnaireStats: {
		mostRecentQuestionnaire: {
			date: string;
		};
		amountOfStartedQuestionnaires: number;
	};
	groupsStats: GroupStats[];
	ageSpansStats: {
		ageFrom: number;
		ageTo: number;
		declared: number;
		fulfilled: number;
	}[];
	crossStats: {
		id: string;
		name: string;
		crossings: CrossStat[];
	}[];
	invitedStats: {
		id: string;
		name: string;
		data: { panelist: string; result: QuestionnaireStatus }[];
	}[];
	urls: Record<string, ResearchGroupUrlStats>;
	finance: {
		groups: {
			group: string;
			cpi: number;
			cptg: number;
			initialCpi: number;
			initialCptg: number;
		}[];
		research: { cptg: number; initalCptg: number }[];
	};
	contactPersons: ContactPerson[];
	dates: {
		startedAt: { date: string | null };
		approximatedFinishDate: { date: string | null };
	};
}

export enum ResearchHisotryLogOperationType {
	CHANGE_STATUS,
	REMINDER,
	GROUP_FINISHED,
	QUESTIONNAIRES_SEND,
}

export interface ResearchHistoryLog {
	id: string;
	operationType: ResearchHisotryLogOperationType;
	createdAt: string;
	data: {
		status: ResearchStatus;
	};
}

export interface ResearchHistory {
	researchHistory: ResearchHistoryLog[];
}

export interface ResearchToken {
	id: string;
	completed: string;
	screenOut: string;
	quotaFull: string;
	qual: string;
}

export interface CheckPanelistsResult {
	inactive: number;
	active: number;
	suspended: number;
	invalid: number;
	notFound: number;
}

export enum RegionType {
	PROVINCE,
	COUNTY,
	CITY,
}

export interface Region {
	regionId: string;
	province: string | null;
	county: string | null;
	city: string | null;
}

export interface RegionData extends Region {
	id: string;
	gender: Gender;
	ageFrom: number;
	ageTo: number;
	value: number;
	regionType: RegionType;
}

export interface RegionAgeSpanStats {
	ageFrom: number;
	ageTo: number;
	all: number;
	male: number;
	female: number;
}

export interface GusRegionStats {
	[regionId: string]: RegionAgeSpanStats[];
}

export type ExportResearchSamplePayload = {
	[researchGroupName: string]: Record<string, Record<string, number>>;
};

import React from 'react';
import styles from './Loading.module.scss';

interface Props {
	fullHeight?: boolean;
}

export const Loading: React.FC<Props> = ({ fullHeight }) => {
	return (
		<div
			className={styles.container}
			style={fullHeight ? { height: '100vh' } : {}}
		>
			<div className={styles.spinner}>
				<div className={styles.spinnerChase}>
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
					<div className={styles.chaseDot} />
				</div>
			</div>
		</div>
	);
};

import { Role } from '@/types/user';
import { RoleContentPath, RoleAppPath, RoleApiPath } from '@/types/rolePaths';
import { StringSignature } from '@/types/types';
import { ConversationStatus } from '@/api/ConversationApi';
import { formatDistance } from 'date-fns';
import { flatten } from 'lodash';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import PlaceholderQuestionnaires, {
	PlaceholderQuestionnaire,
} from '@/data/PlaceholderQuestionnaires';
import moment from 'moment';

/**
 *
 * @param percent
 * @returns [light color, mainly for bg, darker color, usualy for font]
 */
export function setColorsPerPercent(percent: number, isDefault = false) {
	if (!percent && !isDefault) return ['#f1f5f9', '#64748b'];
	if (percent >= 70) return ['#F0FDFA', '#0D9488'];
	else if (percent >= 40) return ['#FFF7ED', '#FB923C'];
	else return ['#FEF2F2', '#EF4444'];
}

export function formatDateForInput(date: moment.MomentInput, format?: string) {
	return moment(date, format).format('YYYY-MM-DD');
}

export function formatDateForPresentation(date: moment.MomentInput) {
	if (!date) return '-';
	return moment(date).format('D MMMM YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('HH:mm');
}

export function formatExactDate(date: moment.MomentInput = new Date()) {
	return `${formatDateForPresentation(date)}, ${formatTimeForPresentation(
		date
	)}`;
}

export function formatDateForAPI(date: moment.MomentInput) {
	return `${formatDateForInput(date)} ${formatTimeForPresentation(date)}`;
}

const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%._*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

/**
 *
 * @param password one capital one number one samll speciall and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

const PHONE_REGEX =
	/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

/**
 *
 * @param phoneNumber validate international
 * @returns
 */
export function validatePhoneNumber(phoneNumber: string) {
	return PHONE_REGEX.test(phoneNumber) && phoneNumber.length >= 9;
}

/**
 *
 * @param accountNumber validate polish
 * @returns
 */
export function validateAccountNumber(accountNumber: string) {
	const trimmed = accountNumber.trim();
	return /\d{26}/.test(trimmed);
}

/**
 *
 * @param postalCode validate polish
 * @returns
 */
export function validatePostalCode(postalCode: string) {
	const trimmed = postalCode.trim();
	return /^[0-9]{2}-[0-9]{3}/.test(trimmed);
}

export function isValidDateObj(date: moment.MomentInput) {
	return moment(date).isValid();
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function randomDate(start: Date, end: Date) {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
}

export function sortByDate(
	dateA: string | Date,
	dateB: string | Date,
	order: 'asc' | 'desc' = 'desc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function roleToAppPath(role?: Role) {
	switch (role) {
		case Role.CLIENT:
			return RoleAppPath.CLIENT;
		case Role.PANELIST:
			return RoleAppPath.PANELIST;
		case Role.POLLSTER:
			return RoleAppPath.POLLSTER;
		default:
			return 'klient';
	}
}

export function roleToApiPath(role: Role) {
	switch (role) {
		case Role.CLIENT:
			return RoleApiPath.CLIENT;
		case Role.PANELIST:
			return RoleApiPath.PANELIST;
		case Role.POLLSTER:
			return RoleApiPath.POLLSTER;
	}
}

export function roleToContentPath(role: Role) {
	switch (role) {
		case Role.CLIENT:
			return RoleContentPath.CLIENT;
		case Role.PANELIST:
			return RoleContentPath.PANELIST;
		case Role.POLLSTER:
			return RoleContentPath.POLLSTER;
	}
}

export function stripHTML(string: string) {
	return string.replace(/(<([^>]+)>)/gi, '');
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export function toFixed2(value: number) {
	return value.toFixed(Number.isInteger(value) ? 0 : 2);
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function getPostalCodesFromText(text: string) {
	return flatten([...text.matchAll(/\d{2}-\d{3}/g)]);
}

export function formatSecondsToMin(duration?: number) {
	if (duration === undefined) return '-';

	const hrs = ~~(duration / 3600);
	const mins = ~~((duration % 3600) / 60);
	const secs = ~~duration % 60;

	let result = '';

	if (hrs > 0) {
		result += '' + hrs + ':' + (mins < 10 ? '0' : '');
	}

	result += '' + mins + ':' + (secs < 10 ? '0' : '');
	result += '' + secs;

	return result;
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function dateWithoutOffset(date: Date) {
	return moment(date).add(date.getTimezoneOffset() / 60, 'hours');
}

export function getContent(conent: StringSignature, slug: string) {
	return conent[slug];
}

export function hidePhoneNumber(phone: string) {
	return `
		${phone.substring(0, 3)} 
		${phone.slice(3).replace(/\s/g, '').slice(0, -6)} 
		*** ***`;
}

export function mapConversationStatuses(status: ConversationStatus) {
	switch (status) {
		case ConversationStatus.NEW:
			return 'Nowa rozmowa';
		case ConversationStatus.UNAVAILABLE:
			return 'Abonent chwilowo niedostępny';
		case ConversationStatus.WRONG_NUMBER:
			return 'Numer niedostępny';
		case ConversationStatus.WRONG_PERSON:
			return 'Niewłaściwa osoba';
		case ConversationStatus.MISSED:
			return 'Połączenie nieodebrane';
		case ConversationStatus.SUCCESS:
			return 'Rozmowa zakończona sukcesem';
	}
}

export function formatDateFromNow(date: Date, locale: Locale) {
	return formatDistance(date, new Date(), {
		locale,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function formatPointsName(points = 0) {
	if (points === 1) return 'punkt';
	if (points < 5) return 'punkty';
	return 'punktów';
}

export function fileSizeToMB(size: number | string) {
	return Math.round((Number(size) / 1000000 + Number.EPSILON) * 100) / 100;
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export function sanitizeAccountNumber(str: string) {
	return str.replace(/\D/g, '').slice(0, 26);
}

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return '-';
	return firstName || lastName || '-';
};

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_FILE_SRC}${path}`;
}

export function isImageMimeType(mineType: string) {
	return (IMAGE_MIME_TYPE as string[]).includes(mineType);
}

export function yesNo(state: boolean) {
	return state ? 'Tak' : 'Nie';
}

export function getRandomQuesitionnairePlaceholders(amount = 3) {
	const max = PlaceholderQuestionnaires.length;

	const indicies: number[] = [];

	while (indicies.length < amount) {
		const random = Math.floor(Math.random() * max);
		if (!indicies.includes(random)) indicies.push(random);
	}

	const placeholders: PlaceholderQuestionnaire[] = [];

	for (const index of indicies) {
		placeholders.push(PlaceholderQuestionnaires[index]);
	}

	return placeholders;
}

export function isTimeEqual(
	date1: moment.MomentInput,
	date2: moment.MomentInput
) {
	const format = 'HH:mm';
	return moment(date1).format(format) === moment(date2).format(format);
}

export function readUniqueLinksFromCSV(data: string) {
	const links: string[] = [];
	const rows = data.split(/\r\n|\n/);
	const separator = detectCSVSeparator(rows[0]);

	for (const row of rows) {
		const rowsLinks = row
			.split(separator)
			.map((link) => link.trim())
			.filter((link) => !!link);
		links.push(...rowsLinks);
	}

	return [...new Set(links)];
}

export function detectCSVSeparator(line: string) {
	const separators = [',', ';', '\t', '|', ' '];
	let maxCount = 0;
	let detectedSeparator = ',';

	separators.forEach((sep) => {
		const count = line.split(sep).length - 1;
		if (count > maxCount) {
			maxCount = count;
			detectedSeparator = sep;
		}
	});

	return detectedSeparator;
}

const ageGenderStats = [
	{ age: 16, all: 364736, male: 186866, female: 177870 },
	{ age: 17, all: 353204, male: 181421, female: 171783 },
	{ age: 18, all: 346839, male: 177703, female: 169136 },
	{ age: 19, all: 349755, male: 179177, female: 170578 },
	{ age: 20, all: 362208, male: 185329, female: 176879 },
	{ age: 21, all: 371866, male: 190072, female: 181794 },
	{ age: 22, all: 375482, male: 191744, female: 183738 },
	{ age: 23, all: 386798, male: 197864, female: 188934 },
	{ age: 24, all: 403444, male: 205749, female: 197695 },
	{ age: 25, all: 420271, male: 214565, female: 205706 },
	{ age: 26, all: 432541, male: 219801, female: 212740 },
	{ age: 27, all: 460273, male: 233562, female: 226711 },
	{ age: 28, all: 479849, male: 244389, female: 235460 },
	{ age: 29, all: 498471, male: 253715, female: 244756 },
	{ age: 30, all: 527738, male: 269192, female: 258546 },
	{ age: 31, all: 538796, male: 274629, female: 264167 },
	{ age: 32, all: 541668, male: 276082, female: 265586 },
	{ age: 33, all: 555617, male: 282515, female: 273102 },
	{ age: 34, all: 563550, male: 287317, female: 276233 },
	{ age: 35, all: 585276, male: 297596, female: 287680 },
	{ age: 36, all: 616873, male: 313560, female: 303313 },
	{ age: 37, all: 633969, male: 322549, female: 311420 },
	{ age: 38, all: 650542, male: 329869, female: 320673 },
	{ age: 39, all: 628910, male: 319696, female: 309214 },
	{ age: 40, all: 601198, male: 304358, female: 296840 },
	{ age: 41, all: 611963, male: 309165, female: 302798 },
	{ age: 42, all: 608433, male: 308129, female: 300304 },
	{ age: 43, all: 591619, male: 298733, female: 292886 },
	{ age: 44, all: 587125, male: 296353, female: 290772 },
	{ age: 45, all: 589341, male: 296513, female: 292828 },
	{ age: 46, all: 568951, male: 286079, female: 282872 },
	{ age: 47, all: 547889, male: 275607, female: 272282 },
	{ age: 48, all: 525959, male: 263847, female: 262112 },
	{ age: 49, all: 506004, male: 253607, female: 252397 },
	{ age: 50, all: 484584, male: 241516, female: 243068 },
	{ age: 51, all: 468425, male: 233671, female: 234754 },
	{ age: 52, all: 450363, male: 223353, female: 227010 },
	{ age: 53, all: 441070, male: 218497, female: 222573 },
	{ age: 54, all: 435558, male: 215140, female: 220418 },
	{ age: 55, all: 436184, male: 214486, female: 221698 },
	{ age: 56, all: 439369, male: 215355, female: 224014 },
	{ age: 57, all: 447634, male: 218480, female: 229154 },
	{ age: 58, all: 454790, male: 220333, female: 234457 },
	{ age: 59, all: 456304, male: 219941, female: 236363 },
	{ age: 60, all: 469739, male: 224595, female: 245144 },
	{ age: 61, all: 492502, male: 233156, female: 259346 },
	{ age: 62, all: 520308, male: 244789, female: 275519 },
	{ age: 63, all: 533323, male: 249044, female: 284279 },
	{ age: 64, all: 536371, male: 247987, female: 288384 },
	{ age: 65, all: 525908, male: 241458, female: 284450 },
	{ age: 66, all: 523561, male: 237736, female: 285825 },
	{ age: 67, all: 495824, male: 222105, female: 273719 },
	{ age: 68, all: 484873, male: 214553, female: 270320 },
	{ age: 69, all: 468486, male: 204863, female: 263623 },
	{ age: 70, all: 452248, male: 194318, female: 257930 },
	{ age: 71, all: 424323, male: 181344, female: 242979 },
	{ age: 72, all: 396434, male: 166701, female: 229733 },
	{ age: 73, all: 375585, male: 156464, female: 219121 },
	{ age: 74, all: 341812, male: 139910, female: 201902 },
	{ age: 75, all: 301353, male: 120930, female: 180423 },
	{ age: 76, all: 209825, male: 82552, female: 127273 },
	{ age: 77, all: 200679, male: 77057, female: 123622 },
	{ age: 78, all: 187650, male: 70927, female: 116723 },
	{ age: 79, all: 176643, male: 64951, female: 111692 },
	{ age: 80, all: 179988, male: 64497, female: 115491 },
	{ age: 81, all: 179437, male: 62385, female: 117052 },
	{ age: 82, all: 169256, male: 56813, female: 112443 },
	{ age: 83, all: 157837, male: 51798, female: 106039 },
	{ age: 84, all: 146981, male: 46864, female: 100117 },
	{ age: 85, all: 135903, male: 41902, female: 94001 },
	{ age: 86, all: 120044, male: 36120, female: 83924 },
	{ age: 87, all: 103869, male: 30343, female: 73526 },
	{ age: 88, all: 89080, male: 25448, female: 63632 },
	{ age: 89, all: 79515, male: 21878, female: 57637 },
	{ age: 90, all: 67345, male: 18103, female: 49242 },
	{ age: 91, all: 56237, male: 14731, female: 41506 },
	{ age: 92, all: 42449, male: 10722, female: 31727 },
	{ age: 93, all: 33001, male: 7998, female: 25003 },
	{ age: 94, all: 23547, male: 5448, female: 18099 },
	{ age: 95, all: 17219, male: 3693, female: 13526 },
	{ age: 96, all: 13377, male: 2824, female: 10553 },
	{ age: 97, all: 8786, male: 1833, female: 6953 },
	{ age: 98, all: 6478, male: 1457, female: 5021 },
	{ age: 99, all: 4335, male: 1013, female: 3322 },
];

const regionStats: {
	[province: string]: {
		amount: number;
		county: {
			[county: string]: number;
		};
		city: {
			[city: string]: number;
		};
	};
} = {
	'Województwo dolnośląskie': {
		amount: 2845944,
		city: {
			'Jelenia Góra': 77807,
			Legnica: 97958,
			Wrocław: 641201,
			Wałbrzych: 108944,
			Bardo: 2466,
			Bielawa: 29318,
			Bierutów: 4788,
			Bogatynia: 16913,
			'Boguszów-Gorce': 15085,
			Bolesławiec: 38280,
			Bolków: 4864,
			'Brzeg Dolny': 12440,
			'Bystrzyca Kłodzka': 9810,
			Chocianów: 7735,
			Chojnów: 13107,
			'Duszniki-Zdrój': 4369,
			Dzierżoniów: 32647,
			Głogów: 65757,
			Głuszyca: 6190,
			Góra: 11488,
			'Gryfów Śląski': 6467,
			Jawor: 22301,
			'Jaworzyna Śląska': 5102,
			'Jedlina-Zdrój': 4756,
			'Jelcz-Laskowice': 15550,
			'Kamieniec Ząbkowicki': 4490,
			'Kamienna Góra': 18425,
			Karpacz: 4434,
			'Kąty Wrocławskie': 7081,
			Kłodzko: 26172,
			Kowary: 10565,
			'Kudowa-Zdrój': 9693,
			'Lądek-Zdrój': 5398,
			Leśna: 4344,
			Lubań: 20573,
			Lubawka: 5894,
			Lubin: 71278,
			Lubomierz: 1977,
			'Lwówek Śląski': 8661,
			Mieroszów: 3927,
			Międzybórz: 2328,
			Międzylesie: 2486,
			Milicz: 11139,
			Mirsk: 3751,
			Niemcza: 2905,
			'Nowa Ruda': 21386,
			Nowogrodziec: 4249,
			'Oborniki Śląskie': 9068,
			Oleśnica: 36757,
			Olszyna: 4298,
			Oława: 33051,
			Piechowice: 6037,
			Pieńsk: 5731,
			Pieszyce: 6971,
			'Piława Górna': 6333,
			'Polanica-Zdrój': 6167,
			Polkowice: 22378,
			Prochowice: 3528,
			Prusice: 2216,
			Przemków: 6045,
			Radków: 2393,
			Siechnice: 9096,
			Sobótka: 6918,
			'Stronie Śląskie': 5578,
			Strzegom: 15756,
			Strzelin: 12373,
			Syców: 10287,
			'Szczawno-Zdrój': 5467,
			Szczytna: 5016,
			'Szklarska Poręba': 6584,
			Ścinawa: 5526,
			'Środa Śląska': 9568,
			Świdnica: 55800,
			Świebodzice: 22539,
			'Świeradów-Zdrój': 3953,
			Świerzawa: 2201,
			Trzebnica: 13338,
			Twardogóra: 6565,
			Wąsosz: 2626,
			Węgliniec: 2792,
			Wiązów: 2183,
			Wleń: 1709,
			Wojcieszów: 3549,
			Wołów: 12252,
			Zawidów: 4105,
			'Ząbkowice Śląskie': 14677,
			Zgorzelec: 29534,
			Ziębice: 8465,
			Złotoryja: 15119,
			'Złoty Stok': 2668,
			Żarów: 6621,
			Żmigród: 6423,
		},
		county: {
			'Powiat bolesławiecki': 89574,
			'Powiat dzierżoniowski': 99340,
			'Powiat głogowski': 88189,
			'Powiat górowski': 34362,
			'Powiat jaworski': 49457,
			'Powiat karkonoski': 63004,
			'Powiat kamiennogórski': 42516,
			'Powiat kłodzki': 155075,
			'Powiat legnicki': 55026,
			'Powiat lubański': 53488,
			'Powiat lubiński': 105637,
			'Powiat lwówecki': 45359,
			'Powiat milicki': 36702,
			'Powiat oleśnicki': 107182,
			'Powiat oławski': 76670,
			'Powiat polkowicki': 62882,
			'Powiat strzeliński': 43048,
			'Powiat średzki': 55849,
			'Powiat świdnicki': 155255,
			'Powiat trzebnicki': 85242,
			'Powiat wałbrzyski': 54752,
			'Powiat wołowski': 46455,
			'Powiat wrocławski': 158755,
			'Powiat ząbkowicki': 63629,
			'Powiat zgorzelecki': 87887,
			'Powiat złotoryjski': 42766,
			'Powiat Jelenia Góra': 77807,
			'Powiat Legnica': 97958,
			'Powiat Wrocław': 641201,
			'Powiat Wałbrzych': 108944,
		},
	},
	'Województwo kujawsko-pomorskie': {
		amount: 2027014,
		city: {
			Bydgoszcz: 341692,
			Grudziądz: 92894,
			Toruń: 197812,
			Włocławek: 107745,
			'Aleksandrów Kujawski': 11974,
			Barcin: 7268,
			Brodnica: 28659,
			'Brześć Kujawski': 4551,
			Chełmno: 19063,
			Chełmża: 14285,
			Chodecz: 2266,
			Ciechocinek: 10486,
			'Dobrzyń nad Wisłą': 2054,
			Gniewkowo: 7019,
			'Golub-Dobrzyń': 12318,
			Górzno: 1406,
			Inowrocław: 71132,
			'Izbica Kujawska': 2580,
			'Jabłonowo Pomorskie': 3703,
			Janikowo: 8592,
			'Janowiec Wielkopolski': 3860,
			'Kamień Krajeński': 2338,
			Kcynia: 4475,
			Koronowo: 11004,
			Kowal: 3469,
			'Kowalewo Pomorskie': 4078,
			Kruszwica: 9260,
			Lipno: 14219,
			'Lubień Kujawski': 1363,
			Lubraniec: 2904,
			Łabiszyn: 4480,
			Łasin: 3144,
			Mogilno: 11553,
			Mrocza: 4281,
			'Nakło nad Notecią	': 17917,
			Nieszawa: 1823,
			Nowe: 5679,
			Pakość: 5568,
			'Piotrków Kujawski': 4367,
			Radziejów: 5438,
			'Radzyń Chełmiński': 1777,
			Rypin: 15915,
			'Sępólno Krajeńskie	': 8963,
			Skępe: 3529,
			'Solec Kujawski': 15563,
			Strzelno: 5563,
			Szubin: 9571,
			Świecie: 25335,
			Tuchola: 13497,
			Wąbrzeźno: 13326,
			Więcbork: 5885,
			Żnin: 13489,
		},
		county: {
			'Powiat aleksandrowski': 54701,
			'Powiat brodnicki': 78915,
			'Powiat bydgoski': 121098,
			'Powiat chełmiński': 51427,
			'Powiat golubsko-dobrzyński': 44614,
			'Powiat grudziądzki': 40263,
			'Powiat inowrocławski': 157521,
			'Powiat lipnowski': 65213,
			'Powiat mogileński': 45267,
			'Powiat nakielski': 85866,
			'Powiat radziejowski': 39763,
			'Powiat rypiński': 43190,
			'Powiat sępoleński': 40685,
			'Powiat świecki': 98321,
			'Powiat Toruński': 110053,
			'Powiat tucholski': 48203,
			'Powiat wąbrzeski': 33837,
			'Powiat włocławski': 85420,
			'Powiat żniński': 69663,
			'Powiat Bydgoszcz': 341692,
			'Powiat Grudziądz': 92894,
			'Powiat Toruń': 197812,
			'Powiat Włocławek': 107745,
		},
	},
	'Województwo lubelskie': {
		amount: 2055257,
		city: {
			'Biała Podlaska': 56760,
			Chełm: 60738,
			Lublin: 337788,
			Zamość: 62368,
			Annopol: 2410,
			Bełżyce: 6339,
			Biłgoraj: 25978,
			Bychawa: 4784,
			Dęblin: 15705,
			Frampol: 1427,
			Goraj: 926,
			Hrubieszów: 17041,
			'Janów Lubelski': 11585,
			Józefów: 2455,
			'Józefów nad Wisłą': 896,
			Kamionka: 1696,
			'Kazimierz Dolny': 2537,
			Kock: 3231,
			Krasnobród: 3064,
			Krasnystaw: 18254,
			Kraśnik: 33638,
			Lubartów: 21488,
			'Lubycza Królewska': 2415,
			Łaszczów: 2080,
			Łęczna: 18519,
			Łuków: 29286,
			'Międzyrzec Podlaski': 16572,
			Modliborzyce: 1451,
			Nałęczów: 3713,
			'Opole Lubelskie': 8278,
			'Ostrów Lubelski': 2088,
			Parczew: 10476,
			Piaski: 2511,
			Poniatowa: 8897,
			Puławy: 46664,
			'Radzyń Podlaski': 15330,
			Rejowiec: 2005,
			'Rejowiec Fabryczny': 4293,
			Ryki: 9525,
			Siedliszcze: 1403,
			'Stoczek Łukowski': 2457,
			Szczebrzeszyn: 4920,
			Świdnik: 38564,
			Tarnogród: 3279,
			Terespol: 5428,
			'Tomaszów Lubelski': 18612,
			Tyszowce: 2022,
			Urzędów: 1670,
			Włodawa: 12839,
			Zwierzyniec: 3084,
		},
		county: {
			'Powiat bialski': 109502,
			'Powiat biłgorajski': 99806,
			'Powiat chełmski': 77214,
			'Powiat hrubieszowski': 61444,
			'Powiat janowski': 44911,
			'Powiat krasnostawski': 62165,
			'Powiat kraśnicki': 94022,
			'Powiat lubartowski': 87490,
			'Powiat lubelski': 156748,
			'Powiat łęczyński': 57053,
			'Powiat łukowski': 105852,
			'Powiat opolski': 58353,
			'Powiat parczewski': 34104,
			'Powiat puławski': 111800,
			'Powiat radzyński': 57990,
			'Powiat rycki': 55238,
			'Powiat świdnicki': 71181,
			'Powiat tomaszowski': 81350,
			'Powiat włodawski': 37751,
			'Powiat zamojski': 104775,
			'Powiat Biała Podlaska': 56760,
			'Powiat Chełm': 60738,
			'Powiat Lublin': 337788,
			'Powiat Zamość': 62368,
		},
	},
	'Województwo lubuskie': {
		amount: 989261,
		city: {
			'Gorzów Wielkopolski': 121714,
			'Zielona Góra': 140708,
			Babimost: 3859,
			'Bytom Odrzański': 4219,
			Cybinka: 2764,
			Czerwieńsk: 3911,
			Dobiegniew: 3011,
			Drezdenko: 9883,
			Gozdnica: 2941,
			Gubin: 16427,
			Iłowa: 3831,
			Jasień: 4188,
			Kargowa: 3762,
			'Kostrzyn nad Odrą': 17656,
			Kożuchów: 9231,
			'Krosno Odrzańskie': 11079,
			Lubniewice: 2078,
			Lubsko: 13647,
			Łęknica: 2382,
			Małomice: 3499,
			Międzyrzecz: 17580,
			'Nowa Sól': 37931,
			'Nowe Miasteczko': 2710,
			'Nowogród Bobrzański': 5033,
			'Ośno Lubuskie': 3884,
			Otyń: 1657,
			Rzepin: 6454,
			Skwierzyna: 9412,
			Sława: 4242,
			Słubice: 16510,
			'Strzelce Krajeńskie': 9771,
			Sulechów: 16477,
			Sulęcin: 9930,
			Szlichtyngowa: 1268,
			Szprotawa: 11447,
			Świebodzin: 21428,
			Torzym: 2521,
			Trzciel: 2330,
			Witnica: 6640,
			Wschowa: 13635,
			Zbąszynek: 4980,
			Żagań: 25110,
			Żary: 36783,
		},
		county: {
			'Powiat gorzowski': 72429,
			'Powiat krośnieński': 54362,
			'Powiat międzyrzecki': 56863,
			'Powiat nowosolski': 85321,
			'Powiat słubicki': 46771,
			'Powiat strzelecko-drezdenecki': 48348,
			'Powiat sulęciński': 34791,
			'Powiat świebodziński': 55300,
			'Powiat wschowski': 38426,
			'Powiat zielonogórski': 75497,
			'Powiat żagański': 77737,
			'Powiat żarski': 94883,
			'Powiat Gorzów Wielkopolski': 121714,
			'Powiat Zielona Góra': 140708,
		},
	},
	'Województwo łódzkie': {
		amount: 2404078,
		city: {
			Łódź: 667923,
			'Piotrków Trybunalski': 71655,
			Skierniewice: 47493,
			'Aleksandrów Łódzki': 21768,
			Bełchatów: 56025,
			'Biała Rawska': 3111,
			Błaszki: 2032,
			Brzeziny: 12329,
			Drzewica: 3780,
			Działoszyn: 5709,
			Głowno: 13839,
			Kamieńsk: 2704,
			Koluszki: 12723,
			'Konstantynów Łódzki': 18399,
			Krośniewice: 4207,
			Kutno: 43051,
			Lututów: 2249,
			Łask: 16830,
			Łęczyca: 13664,
			Łowicz: 27687,
			Opoczno: 20526,
			Ozorków: 18988,
			Pabianice: 63479,
			Pajęczno: 6592,
			Piątek: 1674,
			Poddębice: 7190,
			Przedbórz: 3414,
			Radomsko: 44977,
			'Rawa Mazowiecka': 17119,
			Rzgów: 3363,
			Sieradz: 41142,
			Stryków: 3401,
			Sulejów: 6103,
			Szadek: 1895,
			'Tomaszów Mazowiecki': 60860,
			Tuszyn: 7220,
			Uniejów: 2972,
			Warta: 3178,
			Wieluń: 21735,
			Wieruszów: 8408,
			Wolbórz: 2293,
			'Zduńska Wola': 40996,
			Zelów: 7405,
			Zgierz: 55272,
			Złoczew: 3322,
			Żychlin: 7895,
		},
		county: {
			'Powiat bełchatowski': 112149,
			'Powiat brzeziński': 30589,
			'Powiat kutnowski': 94917,
			'Powiat łaski': 49583,
			'Powiat łęczycki': 48979,
			'Powiat łowicki': 77167,
			'Powiat łódzki wschodni': 72690,
			'Powiat opoczyński': 75109,
			'Powiat pabianicki': 118681,
			'Powiat pajęczański': 50702,
			'Powiat piotrkowski': 90948,
			'Powiat poddębicki': 40743,
			'Powiat radomszczański': 111129,
			'Powiat rawski': 48166,
			'Powiat sieradzki': 116492,
			'Powiat skierniewicki': 38061,
			'Powiat tomaszowski': 115110,
			'Powiat wieluński': 75464,
			'Powiat wieruszowski': 41816,
			'Powiat zduńskowolski': 65796,
			'Powiat zgierski': 165444,
			'Powiat łódź': 667923,
			'Powiat Piotrków Trybunalski': 71655,
			'Powiat skierniewice': 47493,
		},
	},
	'Województwo małopolskie': {
		amount: 3368893,
		city: {
			Kraków: 780796,
			'Nowy Sącz': 83274,
			Tarnów: 106748,
			Alwernia: 3317,
			Andrychów: 19646,
			Biecz: 4513,
			Bobowa: 3129,
			Bochnia: 29405,
			Brzesko: 16663,
			Brzeszcze: 11002,
			Bukowno: 9923,
			Chełmek: 8845,
			Chrzanów: 35881,
			Ciężkowice: 2432,
			Czchów: 2324,
			'Dąbrowa Tarnowska': 11844,
			Dobczyce: 6413,
			Gorlice: 26770,
			Grybów: 5962,
			Jordanów: 5146,
			'Kalwaria Zebrzydowska': 4454,
			Kęty: 18372,
			Koszyce: 770,
			'Krynica-Zdrój': 10353,
			Krzeszowice: 9943,
			Libiąż: 16764,
			Limanowa: 15028,
			'Maków Podhalański': 5739,
			Miechów: 11420,
			'Mszana Dolna': 7889,
			Muszyna: 4715,
			Myślenice: 18267,
			Niepołomice: 14295,
			'Nowe Brzesko': 1651,
			'Nowy Targ': 33212,
			'Nowy Wiśnicz': 2750,
			Olkusz: 34500,
			Oświęcim: 37231,
			'Piwniczna-Zdrój': 5782,
			Proszowice: 5944,
			'Rabka-Zdrój': 12626,
			Radłów: 2744,
			Ryglice: 2820,
			Skała: 3740,
			Skawina: 24302,
			Słomniki: 4277,
			'Stary Sącz': 8986,
			'Sucha Beskidzka': 8990,
			Sułkowice: 6651,
			Szczawnica: 5612,
			Szczucin: 4128,
			'Świątniki Górne': 2445,
			Trzebinia: 19359,
			Tuchów: 6466,
			Wadowice: 18230,
			Wieliczka: 24393,
			Wojnicz: 3295,
			Wolbrom: 8429,
			Zakliczyn: 1618,
			Zakopane: 26662,
			Zator: 3600,
			Żabno: 4167,
		},
		county: {
			'Powiat bocheński': 106826,
			'Powiat brzeski': 92831,
			'Powiat chrzanowski': 123099,
			'Powiat dąbrowski': 58852,
			'Powiat gorlicki': 108193,
			'Powiat krakowski': 282721,
			'Powiat limanowski': 132171,
			'Powiat miechowski': 48280,
			'Powiat myślenicki': 128080,
			'Powiat nowosądecki': 217149,
			'Powiat nowotarski': 191420,
			'Powiat olkuski': 109872,
			'Powiat oświęcimski': 152053,
			'Powiat proszowicki': 42944,
			'Powiat suski': 83561,
			'Powiat tarnowski': 200923,
			'Powiat tatrzański': 67901,
			'Powiat wadowicki': 159333,
			'Powiat wielicki': 131478,
			'Powiat Kraków': 780796,
			'Powiat Nowy Sącz': 83274,
			'Powiat Tarnów': 106748,
		},
	},
	'Województwo mazowieckie': {
		amount: 5398022,
		city: {
			Ostrołęka: 51380,
			Płock: 117573,
			Radom: 208091,
			Siedlce: 77645,
			Warszawa: 1792718,
			Białobrzegi: 6809,
			Bieżuń: 1824,
			Błonie: 12063,
			Brok: 1885,
			Brwinów: 13671,
			Chorzele: 3078,
			Ciechanów: 43664,
			'Czerwińsk nad Wisłą': 1004,
			Drobin: 2789,
			Garwolin: 17563,
			Gąbin: 4066,
			Glinojeck: 2963,
			Gostynin: 18265,
			'Góra Kalwaria': 11934,
			'Grodzisk Mazowiecki': 32594,
			Grójec: 16714,
			Halinów: 3717,
			Iłża: 4598,
			Józefów: 20768,
			Kałuszyn: 2844,
			Karczew: 9749,
			Kobyłka: 25563,
			'Konstancin-Jeziorna': 16813,
			'Kosów Lacki': 2070,
			Kozienice: 16752,
			Legionowo: 53352,
			Lipsko: 5341,
			Lubowidz: 1633,
			Łaskarzew: 4786,
			Łochów: 6801,
			Łomianki: 17114,
			Łosice: 7016,
			'Maków Mazowiecki': 9539,
			Marki: 37384,
			Milanówek: 16497,
			'Mińsk Mazowiecki': 40816,
			Mława: 31130,
			Mogielnica: 2171,
			Mordy: 1703,
			Mrozy: 3571,
			Mszczonów: 6261,
			Myszyniec: 3385,
			Nasielsk: 7602,
			'Nowe Miasto nad Pilicą': 3650,
			'Nowy Dwór Mazowiecki': 28615,
			'Ostrów Mazowiecka': 22160,
			Otwock: 44207,
			'Ożarów Mazowiecki': 12665,
			Piaseczno: 48670,
			Piastów: 22382,
			Pilawa: 4560,
			Pionki: 17735,
			Płońsk: 21846,
			'Podkowa Leśna': 3788,
			Pruszków: 62742,
			Przasnysz: 16979,
			Przysucha: 5734,
			Pułtusk: 19210,
			Raciąż: 4347,
			Radzymin: 13407,
			Różan: 2687,
			Sanniki: 1914,
			Serock: 4691,
			Sierpc: 17698,
			Skaryszew: 4309,
			Sochaczew: 35684,
			Sochocin: 1909,
			'Sokołów Podlaski': 18782,
			'Solec nad Wisłą': 935,
			Sulejówek: 19896,
			Szydłowiec: 11461,
			Tarczyn: 4173,
			Tłuszcz: 8139,
			Warka: 11921,
			Węgrów: 12427,
			Wiskitki: 1332,
			Wołomin: 36623,
			Wyszków: 26935,
			Wyszogród: 2552,
			Wyśmierzyce: 894,
			Zakroczym: 3130,
			Ząbki: 39051,
			Zielonka: 17448,
			Zwoleń: 7617,
			Żelechów: 3883,
			Żuromin: 8679,
			Żyrardów: 39331,
		},
		county: {
			'Powiat białobrzeski': 33232,
			'Powiat ciechanowski': 88476,
			'Powiat garwoliński': 108400,
			'Powiat gostyniński': 44367,
			'Powiat grodziski': 98120,
			'Powiat grójecki': 97662,
			'Powiat kozienicki': 59363,
			'Powiat legionowski': 120654,
			'Powiat lipski': 33428,
			'Powiat łosicki': 30347,
			'Powiat makowski': 44246,
			'Powiat miński': 154988,
			'Powiat mławski': 71978,
			'Powiat nowodworski': 79022,
			'Powiat ostrołęcki': 88381,
			'Powiat ostrowski': 71524,
			'Powiat otwocki': 124171,
			'Powiat piaseczyński': 191905,
			'Powiat płocki': 110504,
			'Powiat płoński': 86374,
			'Powiat pruszkowski': 167112,
			'Powiat przasnyski': 51859,
			'Powiat przysuski': 40919,
			'Powiat pułtuski': 51587,
			'Powiat radomski': 151913,
			'Powiat siedlecki': 81253,
			'Powiat sierpecki': 51250,
			'Powiat sochaczewski': 84621,
			'Powiat sokołowski': 53008,
			'Powiat szydłowiecki': 39168,
			'Powiat warszawski zachodni': 120932,
			'Powiat węgrowski': 65129,
			'Powiat wołomiński': 253856,
			'Powiat wyszkowski': 74107,
			'Powiat zwoleński': 35724,
			'Powiat żuromiński': 37908,
			'Powiat żyrardowski': 75068,
			'Powiat Ostrołęka': 51380,
			'Powiat Płock': 117573,
			'Powiat Radom': 208091,
			'Powiat Siedlce': 77645,
			'Powiat Warszawa': 1792718,
		},
	},
	'Województwo opolskie': {
		amount: 928070,
		city: {
			Opole: 127561,
			Baborów: 2845,
			Biała: 2367,
			Brzeg: 34985,
			Byczyna: 3494,
			Dobrodzień: 3654,
			Głogówek: 5543,
			Głubczyce: 12348,
			Głuchołazy: 13282,
			Gogolin: 6794,
			'Gorzów Śląski': 2438,
			Grodków: 8441,
			'Kędzierzyn-Koźle': 59603,
			Kietrz: 5835,
			Kluczbork: 23131,
			Kolonowskie: 3310,
			Korfantów: 1757,
			Krapkowice: 15883,
			Leśnica: 2552,
			'Lewin Brzeski': 5664,
			Namysłów: 16603,
			Niemodlin: 6156,
			Nysa: 43066,
			Olesno: 9339,
			Otmuchów: 6452,
			Ozimek: 8419,
			Paczków: 7332,
			Praszka: 7431,
			Prószków: 2564,
			Prudnik: 20502,
			'Strzelce Opolskie': 17556,
			Tułowice: 3986,
			Ujazd: 1735,
			Wołczyn: 5740,
			Zawadzkie: 6994,
			Zdzieszowice: 11186,
		},
		county: {
			'Powiat brzeski': 88942,
			'Powiat głubczycki': 44723,
			'Powiat kędzierzyńsko-kozielski': 92493,
			'Powiat kluczborski': 64714,
			'Powiat krapkowicki': 63017,
			'Powiat namysłowski': 42477,
			'Powiat nyski': 134166,
			'Powiat oleski': 63579,
			'Powiat opolski': 123399,
			'Powiat prudnicki': 54457,
			'Powiat strzelecki': 73770,
			'Powiat Opole': 127561,
		},
	},
	'Województwo podkarpackie': {
		amount: 2067289,
		city: {
			Krosno: 45715,
			Przemyśl: 59337,
			Rzeszów: 198476,
			Tarnobrzeg: 46057,
			'Baranów Sandomierski': 1424,
			Błażowa: 2144,
			Boguchwała: 6166,
			Brzostek: 2752,
			Brzozów: 7392,
			Cieszanów: 1900,
			Dębica: 44912,
			Dubiecko: 871,
			Dukla: 2037,
			Dynów: 6049,
			'Głogów Małopolski': 9830,
			'Iwonicz-Zdrój': 1722,
			Jarosław: 36789,
			Jasło: 34303,
			Jedlicze: 5618,
			Kańczuga: 3099,
			Kolbuszowa: 8868,
			Kołaczyce: 1426,
			Lesko: 5375,
			Leżajsk: 13492,
			Lubaczów: 11744,
			Łańcut: 17627,
			Mielec: 59754,
			Narol: 2035,
			Nisko: 15132,
			'Nowa Dęba': 10977,
			'Nowa Sarzyna': 5715,
			Oleszyce: 2933,
			Pilzno: 4961,
			Pruchnik: 3734,
			Przecław: 1790,
			Przeworsk: 15017,
			'Radomyśl Wielki': 3247,
			Radymno: 5193,
			Ropczyce: 15867,
			'Rudnik nad Sanem': 6655,
			Rymanów: 3742,
			Sanok: 36703,
			'Sędziszów Małopolski': 12396,
			Sieniawa: 2116,
			'Sokołów Małopolski': 4204,
			'Stalowa Wola': 59098,
			Strzyżów: 8716,
			Tyczyn: 4021,
			Ulanów: 1421,
			'Ustrzyki Dolne': 8826,
			Zagórz: 5054,
			Zaklików: 2939,
		},
		county: {
			'Powiat bieszczadzki': 21382,
			'Powiat brzozowski': 65129,
			'Powiat dębicki': 134612,
			'Powiat jarosławski': 119240,
			'Powiat jasielski': 112352,
			'Powiat kolbuszowski': 61836,
			'Powiat krośnieński': 111574,
			'Powiat leski': 26255,
			'Powiat leżajski': 68729,
			'Powiat lubaczowski': 54444,
			'Powiat łańcucki': 81107,
			'Powiat mielecki': 136119,
			'Powiat niżański': 65955,
			'Powiat przemyski': 73646,
			'Powiat przeworski': 77697,
			'Powiat ropczycko-sędziszowski': 74544,
			'Powiat rzeszowski': 169800,
			'Powiat sanocki': 93293,
			'Powiat stalowowolski': 104865,
			'Powiat strzyżowski': 60880,
			'Powiat tarnobrzeski': 52534,
			'Powiat Krosno': 45715,
			'Powiat Przemyśl': 59337,
			'Powiat Rzeszów': 198476,
			'Powiat Tarnobrzeg': 46057,
		},
	},
	'Województwo podlaskie': {
		amount: 1135785,
		city: {
			Białystok: 296401,
			Łomża: 62278,
			Suwałki: 69575,
			Augustów: 29785,
			'Bielsk Podlaski': 24883,
			Brańsk: 3667,
			Choroszcz: 5965,
			Ciechanowiec: 4547,
			'Czarna Białostocka': 9144,
			Czyżew: 2620,
			'Dąbrowa Białostocka': 5338,
			Drohiczyn: 1921,
			Goniądz: 1791,
			Grajewo: 21616,
			Hajnówka: 20141,
			Jedwabne: 1597,
			Kleszczele: 1211,
			Knyszyn: 2701,
			Kolno: 10107,
			Krynki: 2298,
			Lipsk: 2234,
			Łapy: 15347,
			Michałowo: 2954,
			Mońki: 9905,
			Nowogród: 2146,
			Rajgród: 1527,
			Sejny: 5124,
			Siemiatycze: 14131,
			Sokółka: 17872,
			Stawiski: 2123,
			Suchowola: 2135,
			Supraśl: 4562,
			Suraż: 980,
			Szczuczyn: 3318,
			Szepietowo: 2146,
			Tykocin: 1961,
			Wasilków: 11882,
			'Wysokie Mazowieckie': 9304,
			Zabłudów: 2485,
			Zambrów: 21853,
		},
		county: {
			'Powiat augustowski': 57316,
			'Powiat białostocki': 151479,
			'Powiat bielski': 53299,
			'Powiat grajewski': 46519,
			'Powiat hajnowski': 41612,
			'Powiat kolneński': 37707,
			'Powiat łomżyński': 50666,
			'Powiat moniecki': 39825,
			'Powiat sejneński': 19550,
			'Powiat siemiatycki': 43295,
			'Powiat sokólski': 65367,
			'Powiat suwalski': 35430,
			'Powiat wysokomazowiecki': 56087,
			'Powiat zambrowski': 43028,
			'Powiat Białystok': 296401,
			'Powiat Łomża': 62278,
			'Powiat Suwałki': 69575,
		},
	},
	'Województwo pomorskie': {
		amount: 2318625,
		city: {
			Gdańsk: 470633,
			Gdynia: 244104,
			Słupsk: 89180,
			Sopot: 35049,
			Brusy: 5220,
			Bytów: 16790,
			Chojnice: 39484,
			'Czarna Woda': 2753,
			Czarne: 5810,
			Czersk: 9870,
			Człuchów: 13386,
			Debrzno: 5017,
			Dzierzgoń: 5291,
			Gniew: 6571,
			Hel: 3165,
			Jastarnia: 2675,
			Kartuzy: 14186,
			Kępice: 3524,
			Kościerzyna: 23698,
			'Krynica Morska': 1284,
			Kwidzyn: 38179,
			Lębork: 34977,
			Łeba: 3525,
			Malbork: 37898,
			Miastko: 10131,
			'Nowy Dwór Gdański': 9813,
			'Nowy Staw': 4188,
			Pelplin: 7549,
			Prabuty: 8589,
			'Pruszcz Gdański': 31822,
			Puck: 11089,
			Reda: 26838,
			Rumia: 49672,
			Skarszewy: 6974,
			Skórcz: 3585,
			'Starogard Gdański': 47037,
			Sztum: 9746,
			Tczew: 59105,
			Ustka: 15047,
			Wejherowo: 48735,
			Władysławowo: 9814,
			Żukowo: 6743,
		},
		county: {
			'Powiat bytowski': 78939,
			'Powiat chojnicki': 97536,
			'Powiat człuchowski': 55615,
			'Powiat gdański': 121406,
			'Powiat kartuski': 143101,
			'Powiat kościerski': 72951,
			'Powiat kwidzyński': 82795,
			'Powiat lęborski': 65969,
			'Powiat malborski': 62953,
			'Powiat nowodworski': 35314,
			'Powiat pucki': 88012,
			'Powiat słupski': 98823,
			'Powiat starogardzki': 127906,
			'Powiat sztumski': 40882,
			'Powiat tczewski': 114843,
			'Powiat wejherowski': 220054,
			'Powiat Gdańsk': 470633,
			'Powiat Gdynia': 244104,
			'Powiat Słupsk': 89180,
			'Powiat Sopot': 35049,
		},
	},
	'Województwo śląskie': {
		amount: 4413746,
		city: {
			'Bielsko-Biała': 168835,
			Bytom: 162250,
			Chorzów: 106272,
			Częstochowa: 215905,
			'Dąbrowa Górnicza': 117516,
			Gliwice: 176009,
			'Jastrzębie-Zdrój': 87322,
			Jaworzno: 89827,
			Katowice: 289162,
			Mysłowice: 74413,
			'Piekary Śląskie': 54468,
			'Ruda Śląska': 135695,
			Rybnik: 136464,
			'Siemianowice Śląskie': 65845,
			Sosnowiec: 195978,
			Świętochłowice: 48817,
			Tychy: 126184,
			Zabrze: 169831,
			Żory: 62815,
			Będzin: 55542,
			Bieruń: 19328,
			Blachownia: 9420,
			Cieszyn: 33757,
			'Czechowice-Dziedzice': 35747,
			Czeladź: 30854,
			'Czerwionka-Leszczyny': 27790,
			Imielin: 9333,
			Kalety: 8502,
			Kłobuck: 12709,
			Knurów: 37657,
			Koniecpol: 5788,
			Koziegłowy: 2441,
			Krzanowice: 2113,
			Krzepice: 4329,
			'Kuźnia Raciborska': 5195,
			Lędziny: 16721,
			Lubliniec: 23430,
			'Łaziska Górne': 22062,
			Łazy: 6696,
			'Miasteczko Śląskie': 7378,
			Mikołów: 40994,
			Myszków: 30988,
			Ogrodzieniec: 4193,
			Orzesze: 21324,
			Pilica: 1911,
			Poręba: 8418,
			Pszczyna: 25622,
			Pszów: 13668,
			Pyskowice: 18368,
			Racibórz: 54004,
			Radlin: 17567,
			Radzionków: 16883,
			Rydułtowy: 21298,
			Siewierz: 5565,
			Skoczów: 14041,
			Sławków: 6924,
			Sośnicowice: 1938,
			Strumień: 3632,
			Szczekociny: 3491,
			Szczyrk: 5671,
			'Tarnowskie Góry': 61789,
			Toszek: 3544,
			Ustroń: 15932,
			Wilamowice: 3151,
			Wisła: 10964,
			'Wodzisław Śląski': 47319,
			Wojkowice: 8847,
			Woźniki: 4283,
			Zawiercie: 48412,
			Żarki: 4515,
			Żywiec: 30526,
		},
		county: {
			'Powiat będziński': 146527,
			'Powiat bielski': 166405,
			'Powiat bieruńsko-lędziński': 59898,
			'Powiat cieszyński': 177030,
			'Powiat częstochowski': 133650,
			'Powiat gliwicki': 115207,
			'Powiat kłobucki': 83984,
			'Powiat lubliniecki': 75826,
			'Powiat mikołowski': 99253,
			'Powiat myszkowski': 70111,
			'Powiat pszczyński': 111748,
			'Powiat raciborski': 106970,
			'Powiat rybnicki': 78054,
			'Powiat tarnogórski': 140791,
			'Powiat wodzisławski': 155955,
			'Powiat zawierciański': 115955,
			'Powiat żywiecki': 151731,
			'Powiat Bielsko-Biała': 168835,
			'Powiat Bytom': 162250,
			'Powiat Chorzów': 106272,
			'Powiat Częstochowa': 215905,
			'Powiat Dąbrowa Górnicza': 117516,
			'Powiat Gliwice': 176009,
			'Powiat Jastrzębie Zdrój': 87322,
			'Powiat Jaworzno': 89827,
			'Powiat Katowice': 289162,
			'Powiat Mysłowice': 74413,
			'Powiat Piekary Śląskie': 54468,
			'Powiat Ruda Śląska': 135695,
			'Powiat Rybnik': 136464,
			'Powiat Siemianowice Śląskie': 65845,
			'Powiat Sosnowiec': 195978,
			'Powiat Świętochłowice': 48817,
			'Powiat Tychy': 126184,
			'Powiat Zabrze': 169831,
			'Powiat Żory': 62815,
		},
	},
	'Województwo świętokrzyskie': {
		amount: 1197253,
		city: {
			Kielce: 192468,
			Bodzentyn: 2174,
			'Busko-Zdrój': 15462,
			Chęciny: 4377,
			Chmielnik: 3577,
			Ćmielów: 2977,
			Daleszyce: 2844,
			Działoszyce: 887,
			Jędrzejów: 14706,
			'Kazimierza Wielka': 5384,
			Klimontów: 1862,
			Końskie: 18723,
			Koprzywnica: 2429,
			Kunów: 2870,
			Łagów: 1528,
			Małogoszcz: 3647,
			Morawica: 1699,
			'Nowa Słupia': 1346,
			'Nowy Korczyn': 912,
			Oleśnica: 1786,
			Opatowiec: 338,
			Opatów: 6320,
			Osiek: 1960,
			'Ostrowiec Świętokrzyski': 66837,
			Ożarów: 4418,
			Pacanów: 1141,
			Pierzchnica: 1155,
			Pińczów: 10437,
			Połaniec: 7917,
			Radoszyce: 3064,
			Sandomierz: 22803,
			Sędziszów: 6306,
			Skalbmierz: 1263,
			'Skarżysko-Kamienna': 43804,
			Starachowice: 47238,
			Staszów: 14440,
			Stąporków: 5507,
			Stopnica: 1408,
			Suchedniów: 8185,
			Szydłów: 1090,
			Wąchock: 2701,
			Wiślica: 506,
			Włoszczowa: 9736,
			Wodzisław: 1088,
			Zawichost: 1701,
		},
		county: {
			'Powiat buski': 70687,
			'Powiat jędrzejowski': 84435,
			'Powiat kazimierski': 33205,
			'Powiat kielecki': 210689,
			'Powiat konecki': 78506,
			'Powiat opatowski': 51149,
			'Powiat ostrowiecki': 106910,
			'Powiat pińczowski': 38237,
			'Powiat sandomierski': 75900,
			'Powiat skarżyski': 72482,
			'Powiat starachowicki': 87968,
			'Powiat staszowski': 70928,
			'Powiat włoszczowski': 44525,
			'Powiat Kielce': 192468,
		},
	},
	'Województwo warmińsko-mazurskie': {
		amount: 1380968,
		city: {
			Elbląg: 117952,
			Olsztyn: 170622,
			Barczewo: 7478,
			Bartoszyce: 22785,
			'Biała Piska': 4025,
			Biskupiec: 10576,
			Bisztynek: 2295,
			Braniewo: 16907,
			'Dobre Miasto': 9943,
			Działdowo: 21014,
			Ełk: 61782,
			Frombork: 2284,
			Giżycko: 28803,
			Gołdap: 13571,
			'Górowo Iławeckie': 3840,
			Iława: 33111,
			Jeziorany: 3105,
			Kętrzyn: 26609,
			Kisielice: 2065,
			Korsze: 4121,
			Lidzbark: 7596,
			'Lidzbark Warmiński': 15420,
			Lubawa: 10369,
			Mikołajki: 3718,
			Miłakowo: 2531,
			Miłomłyn: 2407,
			Młynary: 1756,
			Morąg: 13325,
			Mrągowo: 21179,
			Nidzica: 13439,
			'Nowe Miasto Lubawskie': 10657,
			Olecko: 16241,
			Olsztynek: 7474,
			Orneta: 8598,
			Orzysz: 5473,
			Ostróda: 32547,
			Pasłęk: 12085,
			Pasym: 2492,
			Pieniężno: 2662,
			Pisz: 18890,
			Reszel: 4502,
			'Ruciane-Nida': 4399,
			Ryn: 2805,
			Sępopol: 1868,
			Susz: 5490,
			Szczytno: 22671,
			Tolkmicko: 2611,
			Węgorzewo: 11155,
			Wielbark: 2960,
			Zalewo: 2112,
		},
		county: {
			'Powiat bartoszycki': 56077,
			'Powiat braniewski': 40511,
			'Powiat działdowski': 64415,
			'Powiat elbląski': 56762,
			'Powiat ełcki': 91216,
			'Powiat giżycki': 55943,
			'Powiat gołdapski': 26396,
			'Powiat iławski': 92336,
			'Powiat kętrzyński': 61369,
			'Powiat lidzbarski': 40641,
			'Powiat mrągowski': 49192,
			'Powiat nidzicki': 32431,
			'Powiat nowomiejski': 43556,
			'Powiat olecki': 33807,
			'Powiat olsztyński': 127769,
			'Powiat ostródzki': 103001,
			'Powiat piski': 55496,
			'Powiat szczycieński': 68775,
			'Powiat węgorzewski': 22376,
			'Powiat Elbląg': 117952,
			'Powiat Olsztyn': 170622,
		},
	},
	'Województwo wielkopolskie': {
		amount: 3471812,
		city: {
			Kalisz: 98447,
			Konin: 71935,
			Leszno: 62429,
			Poznań: 530464,
			'Poznań-Grunwald': 114827,
			'Poznań-Jeżyce': 80249,
			'Poznań-Nowe Miasto': 128977,
			'Poznań-Stare Miasto': 149241,
			'Poznań-Wilda': 57170,
			Bojanowo: 2895,
			'Borek Wielkopolski': 2485,
			Budzyń: 4489,
			Buk: 5951,
			Chocz: 1770,
			Chodzież: 18117,
			Czarnków: 10373,
			Czempiń: 4923,
			Czerniejewo: 2657,
			Dąbie: 1963,
			Dobra: 1343,
			Dobrzyca: 3099,
			Dolsk: 1553,
			Gniezno: 67157,
			Golina: 4444,
			Gołańcz: 3144,
			Gostyń: 20141,
			'Grabów nad Prosną': 1908,
			'Grodzisk Wielkopolski': 14700,
			Jaraczewo: 1393,
			Jarocin: 26373,
			Jastrowie: 8564,
			Jutrosin: 1962,
			Kępno: 13838,
			Kleczew: 4068,
			Kłecko: 2587,
			Kłodawa: 6300,
			Kobylin: 3226,
			Koło: 21035,
			Kostrzyn: 9681,
			Kościan: 23596,
			'Koźmin Wielkopolski': 6403,
			Koźminek: 1940,
			Kórnik: 8042,
			Krajenka: 3574,
			Krobia: 4331,
			Krotoszyn: 28580,
			Krzywiń: 1721,
			'Krzyż Wielkopolski': 6094,
			'Książ Wielkopolski': 2704,
			Luboń: 31978,
			Lwówek: 2924,
			Łobżenica: 2920,
			Margonin: 2977,
			'Miejska Górka': 3186,
			Międzychód: 10446,
			Mikstat: 1792,
			Miłosław: 3514,
			Mosina: 14280,
			'Murowana Goślina': 10380,
			Nekla: 3857,
			'Nowe Skalmierzyce': 4721,
			'Nowy Tomyśl': 14181,
			Oborniki: 17747,
			Obrzycko: 2376,
			Odolanów: 5063,
			Okonek: 3830,
			Opalenica: 9520,
			Opatówek: 3589,
			Osieczna: 2405,
			Ostroróg: 1919,
			'Ostrów Wielkopolski': 71254,
			Ostrzeszów: 13904,
			Piła: 72184,
			Pleszew: 16855,
			Pniewy: 8078,
			Pobiedziska: 9470,
			Pogorzela: 2092,
			Poniec: 2877,
			Przedecz: 1612,
			Puszczykowo: 9530,
			Pyzdry: 3072,
			Rakoniewice: 3613,
			Raszków: 2111,
			Rawicz: 20044,
			Rogoźno: 11064,
			Rychwał: 2340,
			Rydzyna: 2943,
			Sieraków: 5937,
			Skoki: 4505,
			Słupca: 13505,
			Sompolno: 3449,
			Stawiszyn: 1442,
			Stęszew: 5909,
			Sulmierzyce: 2820,
			Swarzędz: 29663,
			Szamocin: 4188,
			Szamotuły: 18639,
			Ślesin: 3091,
			Śmigiel: 5580,
			Śrem: 29022,
			'Środa Wielkopolska': 23655,
			Trzcianka: 16924,
			Trzemeszno: 7558,
			Tuliszków: 3176,
			Turek: 26201,
			Ujście: 3591,
			Wągrowiec: 25526,
			Wieleń: 5714,
			Wielichowo: 1753,
			Witkowo: 7653,
			Wolsztyn: 12767,
			Wronki: 10992,
			Września: 30965,
			Wyrzysk: 5075,
			Wysoka: 2612,
			Zagórów: 2873,
			Zbąszyń: 7179,
			Zduny: 4439,
			Złotów: 18373,
			Żerków: 2112,
		},
		county: {
			'Powiat chodzieski': 46669,
			'Powiat czarnkowsko-trzcianecki': 86116,
			'Powiat gnieźnieński': 144662,
			'Powiat gostyński': 75509,
			'Powiat grodziski': 51819,
			'Powiat jarociński': 71140,
			'Powiat kaliski': 82793,
			'Powiat kępiński': 56220,
			'Powiat kolski': 85707,
			'Powiat koniński': 129830,
			'Powiat kościański': 78598,
			'Powiat krotoszyński': 76782,
			'Powiat leszczyński': 58255,
			'Powiat międzychodzki': 36517,
			'Powiat nowotomyski': 75457,
			'Powiat obornicki': 59719,
			'Powiat ostrowski': 161006,
			'Powiat ostrzeszowski': 55176,
			'Powiat pilski': 134939,
			'Powiat pleszewski': 62615,
			'Powiat poznański': 409974,
			'Powiat rawicki': 59956,
			'Powiat słupecki': 58778,
			'Powiat szamotulski': 91686,
			'Powiat średzki': 59194,
			'Powiat śremski': 61397,
			'Powiat turecki': 83318,
			'Powiat wągrowiecki': 69970,
			'Powiat wolsztyński': 57429,
			'Powiat wrzesiński': 77919,
			'Powiat złotowski': 68985,
			'Powiat Kalisz': 98447,
			'Powiat Konin': 71935,
			'Powiat Leszno': 62429,
			'Powiat Poznań': 530464,
		},
	},
	'Województwo zachodniopomorskie': {
		amount: 1652230,
		city: {
			Koszalin: 105801,
			Szczecin: 396472,
			Świnoujście: 40696,
			Barlinek: 13541,
			Barwice: 3668,
			Białogard: 23811,
			'Biały Bór': 2213,
			Bobolice: 3936,
			'Borne Sulinowo': 5008,
			Cedynia: 1484,
			Chociwel: 3103,
			Chojna: 7338,
			Choszczno: 14894,
			Czaplinek: 7040,
			Człopa: 2293,
			Darłowo: 13459,
			Dębno: 13521,
			Dobra: 2272,
			Dobrzany: 2207,
			Drawno: 2216,
			'Drawsko Pomorskie': 11343,
			Dziwnów: 2624,
			Golczewo: 2674,
			Goleniów: 21876,
			Gościno: 2436,
			Gryfice: 16130,
			Gryfino: 20792,
			Ińsko: 1881,
			'Kalisz Pomorski': 4357,
			'Kamień Pomorski': 8627,
			Karlino: 5826,
			Kołobrzeg: 45930,
			Lipiany: 3870,
			Łobez: 9977,
			Maszewo: 3340,
			Mielno: 2857,
			Mieszkowice: 3550,
			Międzyzdroje: 5274,
			Mirosławiec: 3021,
			Moryń: 1605,
			Myślibórz: 11021,
			'Nowe Warpno': 1176,
			Nowogard: 16366,
			Pełczyce: 2585,
			Płoty: 3888,
			Polanów: 2877,
			Police: 32034,
			'Połczyn-Zdrój': 7940,
			Pyrzyce: 12435,
			Recz: 2856,
			Resko: 4127,
			Sianów: 6547,
			Sławno: 12288,
			Stargard: 67430,
			Stepnica: 2447,
			Suchań: 1431,
			Szczecinek: 39705,
			Świdwin: 15268,
			'Trzcińsko-Zdrój': 2193,
			Trzebiatów: 9862,
			Tuczno: 1917,
			Tychowo: 2497,
			Wałcz: 24802,
			Węgorzyno: 2751,
			Wolin: 4727,
			Złocieniec: 12639,
		},
		county: {
			'Powiat białogardzki': 47100,
			'Powiat choszczeński': 47682,
			'Powiat drawski': 56159,
			'Powiat goleniowski': 82136,
			'Powiat gryficki': 59609,
			'Powiat gryfiński': 80955,
			'Powiat kamieński': 46495,
			'Powiat kołobrzeski': 78979,
			'Powiat koszaliński': 66518,
			'Powiat łobeski': 36251,
			'Powiat myśliborski': 65064,
			'Powiat policki': 82193,
			'Powiat pyrzycki': 38963,
			'Powiat sławieński': 55469,
			'Powiat stargardzki': 119946,
			'Powiat szczecinecki': 76960,
			'Powiat świdwiński': 46127,
			'Powiat wałecki': 52428,
			'Powiat Koszalin': 105801,
			'Powiat Szczecin': 396472,
			'Powiat Świnoujście': 40696,
		},
	},
};

const ageSpans = [
	{ ageFrom: 18, ageTo: 24 },
	{ ageFrom: 25, ageTo: 34 },
	{ ageFrom: 35, ageTo: 44 },
	{ ageFrom: 45, ageTo: 54 },
	{ ageFrom: 55, ageTo: 64 },
	{ ageFrom: 65, ageTo: 99 },
];

const data = {
	ageGenderStats,
	regionStats,
	ageSpans,
};

export default data;

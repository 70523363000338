import { QueryFunctionContext } from '@tanstack/react-query';
import { Client, Panelist } from '@/types/user';
import httpClient from './clients/httpClient';

export enum AffiliationType {
	PANELIST,
	CLIENT,
}

type BaseAffiliation = {
	id: string;
	type: AffiliationType;
	isGranted: boolean;
	createdAt: string;
};

export type PanelistAffiliation = BaseAffiliation & {
	panelist?: Panelist;
	invitedBy: Panelist;
	type: AffiliationType.PANELIST;
};

export type ClientAffiliation = BaseAffiliation & {
	client?: Client;
	clientInvitedBy: Client;
	type: AffiliationType.CLIENT;
};

export type Affiliation = PanelistAffiliation | ClientAffiliation;

abstract class AffiliationApi {
	static getByInvitedBy = async ({
		queryKey,
	}: QueryFunctionContext<[string, string, AffiliationType]>) => {
		const params = {
			pagination: false,
		};

		switch (queryKey[2]) {
			case AffiliationType.PANELIST:
				Object.assign(params, { invitedBy: queryKey[1] });
				break;
			case AffiliationType.CLIENT:
				Object.assign(params, { clientInvitedBy: queryKey[1] });
				break;
		}

		const response = await httpClient.get<Affiliation[]>(queryKey[0], {
			params,
		});

		return response.data;
	};
}
export default AffiliationApi;

import {
	ResearchGroup,
	Cross,
	LocalizationTypes,
	Question,
	Feasibility,
	Region,
	CrossSwitches,
	ResearchStatus,
	GusRegionStats,
	ExportResearchSamplePayload,
} from '@/types/research';
import { Gender, Language, StringSignature } from '@/types/types';
import { QuestionnaireStatus } from '@/api/QuestionnaireApi';
import { clone, flatten, isEmpty, isEqual, omit, pick } from 'lodash';
import { formatDateForInput } from './utilities';
import { ContentContext } from '@/providers/ContentProvider';
import APP_CONFIG from '@/configs/appConfig';
import GUSData from '@/data/GUSData';
import hash from 'object-hash';

export const AGE_SPANS = [
	[APP_CONFIG.MIN_AGE, 24],
	[25, 34],
	[35, 44],
	[45, 54],
	[55, 64],
	[65, APP_CONFIG.MAX_AGE],
];

export function generateAgeSpans(
	span: [number, number],
	quantity: number
): { id: string; span: [number, number]; value: number }[] {
	const result: { id: string; span: [number, number]; value: number }[] = [];

	const first = AGE_SPANS.find((s) => span[0] >= s[0] && span[0] <= s[1]);

	if (!first) return result;

	result.push({
		id: crypto.randomUUID(),
		span: [span[0], Math.min(first[1], span[1])],
		value: 0,
	});

	for (const sp of AGE_SPANS) {
		if (sp[1] < span[1] && sp[0] > first[1])
			result.push({ id: crypto.randomUUID(), span: [sp[0], sp[1]], value: 0 });
	}

	const last = AGE_SPANS.find((s) => s[1] >= span[1]);

	if (last && last[1] !== first[1])
		result.push({
			id: crypto.randomUUID(),
			span: [last[0], span[1]],
			value: 0,
		});

	return result.map((sp, idx) => ({
		...sp,
		value:
			Math.floor(quantity / result.length) +
			(idx < quantity % result.length ? 1 : 0),
	}));
}

export function generateCrossing(group: ResearchGroup) {
	const { crossSwitches, userCrosses, questionsChosen, quantity } = group;

	const markedSwitches = countCrossSwitches(crossSwitches);

	let crossing: Cross[] =
		markedSwitches > 1
			? userCrosses.map((cross) => omit(cross, 'feasibility'))
			: [
					{
						gender: Gender.BOTH,
						ageFrom: group.ageSpan[0],
						ageTo: group.ageSpan[1],
						answers: [],
						province: null,
						county: null,
						city: null,
						postalCode: null,
						amount: group.amountOfQuestionnaires,
					},
				];

	if (markedSwitches !== questionsChosen.length + 3) {
		const shouldCrossGenders =
				!crossSwitches.gender &&
				(!quantity.gender.isAuto || group.gender !== Gender.BOTH),
			shouldCrossRegions =
				!crossSwitches.region &&
				(!quantity.region.isAuto ||
					group.regionSwitch !== LocalizationTypes.COUNTRY),
			shouldCrossAgeSpans = !crossSwitches.age && !quantity.age.isAuto;

		if (shouldCrossGenders) crossing = generateGenderCrosses(crossing, group);
		if (shouldCrossRegions) crossing = generateRegionCrosses(crossing, group);
		if (shouldCrossAgeSpans) crossing = generateAgeCrosses(crossing, group);

		const crossedQuestionIds = Object.keys(
			omit(crossSwitches, ['age', 'gender'])
		).filter((key) => crossSwitches[key]);

		const unCrossedQuestions = questionsChosen.filter(
			(qc) => !crossedQuestionIds.includes(qc.id)
		);

		for (const question of unCrossedQuestions) {
			crossing = generateQuestionCrosses(question, crossing, group);
		}
	}

	for (const cross of crossing) {
		const id = hash(
			{
				...omit(cross, ['id', 'amount', 'feasibility']),
				answers: cross.answers.map((a) => a.id),
			},
			{
				algorithm: 'md5',
			}
		);

		cross.id = id;
	}

	return crossing;
}

export function generateCrossingFromData(
	group: ResearchGroup,
	data: GusRegionStats,
	skipQuestionCrossing = false
) {
	let crossing: Cross[] = [];

	for (const region of group.regionsChosen) {
		for (let idx = 0; idx < GUSData.ageSpans.length; idx++) {
			const span = GUSData.ageSpans[idx];
			if (group.gender === Gender.MALE || group.gender === Gender.BOTH) {
				crossing.push({
					ageFrom: span.ageFrom,
					ageTo: span.ageTo,
					gender: Gender.MALE,
					province: region.province,
					county: region.county,
					city: region.city,
					postalCode: null,
					amount: data[region.regionId][idx].male,
					answers: [],
				});
			}
			if (group.gender === Gender.FEMALE || group.gender === Gender.BOTH) {
				crossing.push({
					ageFrom: span.ageFrom,
					ageTo: span.ageTo,
					gender: Gender.FEMALE,
					province: region.province,
					county: region.county,
					city: region.city,
					postalCode: null,
					amount: data[region.regionId][idx].female,
					answers: [],
				});
			}
		}
	}

	if (!skipQuestionCrossing)
		for (const question of group.questionsChosen) {
			crossing = generateQuestionCrosses(question, crossing, group);
		}

	for (const cross of crossing) {
		const id = hash(
			{
				...omit(cross, ['id', 'amount', 'feasibility']),
				answers: [],
			},
			{
				algorithm: 'md5',
			}
		);
		cross.id = id;
	}

	const amountSum = crossing.reduce((acc, curr) => acc + curr.amount, 0);

	let restArray: { id?: string; rest: number }[] = [];

	for (const cross of crossing) {
		const raw = (cross.amount * group.amountOfQuestionnaires) / amountSum;
		const floored = Math.floor(raw);
		const rest = raw - floored;

		restArray.push({ id: cross.id, rest });

		cross.amount = floored;
	}

	const resultSum = crossing.reduce((a, c) => a + c.amount, 0);

	const rest = group.amountOfQuestionnaires - resultSum;

	restArray = restArray.sort((a, b) => b.rest - a.rest).splice(0, rest);

	for (const cross of crossing) {
		if (restArray.some((r) => r.id === cross.id)) cross.amount++;
	}

	return crossing;
}

export const generateGenderCrosses = (
	crosses: Cross[],
	group: ResearchGroup
) => {
	return group.gender === Gender.BOTH
		? flatten(
				crosses.map((c) => {
					const maleCross = {
						...c,
						gender: Gender.MALE,
						amount: Math.floor(
							c.amount *
								(group.quantity.gender.male / group.amountOfQuestionnaires)
						),
					};

					const femaleCross = {
						...c,
						gender: Gender.FEMALE,
						amount: Math.floor(
							c.amount *
								(group.quantity.gender.female / group.amountOfQuestionnaires)
						),
					};

					const rest = c.amount - maleCross.amount - femaleCross.amount;

					return [maleCross, femaleCross].map((c, idx) => ({
						...c,
						amount: c.amount + (idx < rest ? 1 : 0),
					}));
				})
			)
		: crosses.map((c) => ({ ...c, gender: group.gender }));
};

export const generateRegionCrosses = (
	crosses: Cross[],
	group: ResearchGroup
) => {
	if (!group.regionsChosen.length && !group.postalCodesChosen.length)
		return crosses;

	if (group.regionSwitch === LocalizationTypes.POSTAL_CODE)
		return flatten(
			crosses.map((c) => {
				const postalCodeCrosses = group.quantity.region.postalCodes.map(
					(codeCross) => {
						return {
							...c,
							province: null,
							county: null,
							city: null,
							postalCode: codeCross.code,
							amount: Math.floor(
								c.amount * (codeCross.value / group.amountOfQuestionnaires)
							),
						};
					}
				);

				const rest =
					c.amount -
					postalCodeCrosses.reduce((acc, cross) => acc + cross.amount, 0);

				for (let idx = 0; idx < postalCodeCrosses.length; idx++) {
					const cross = postalCodeCrosses[idx];
					cross.amount = cross.amount + (idx < rest ? 1 : 0);
				}

				return postalCodeCrosses;
			})
		);

	return flatten(
		crosses.map((c) => {
			const regionCrosses = group.quantity.region.regions.map((regionCross) => {
				return {
					...c,
					province: regionCross.region.province,
					county: regionCross.region.county,
					city: regionCross.region.city,
					postalCode: null,
					amount: Math.floor(
						c.amount * (regionCross.value / group.amountOfQuestionnaires)
					),
				};
			});

			const rest =
				c.amount - regionCrosses.reduce((acc, cross) => acc + cross.amount, 0);

			for (let idx = 0; idx < regionCrosses.length; idx++) {
				const cross = regionCrosses[idx];
				cross.amount = cross.amount + (idx < rest ? 1 : 0);
			}

			return regionCrosses;
		})
	);
};

export const generateAgeCrosses = (crosses: Cross[], group: ResearchGroup) => {
	return !group.quantity.age.spans.length
		? crosses
		: flatten(
				crosses.map((c) => {
					const ageCrosses = group.quantity.age.spans.map((ageCross) => {
						return {
							...c,
							ageFrom: ageCross.span[0],
							ageTo: ageCross.span[1],
							amount: Math.floor(
								c.amount * (ageCross.value / group.amountOfQuestionnaires)
							),
						};
					});

					const rest =
						c.amount - ageCrosses.reduce((acc, cross) => acc + cross.amount, 0);

					for (let idx = 0; idx < ageCrosses.length; idx++) {
						const cross = ageCrosses[idx];
						cross.amount = cross.amount + (idx < rest ? 1 : 0);
					}

					return ageCrosses;
				})
			);
};

export const generateQuestionCrosses = (
	question: Question,
	crosses: Cross[],
	group: ResearchGroup
) => {
	return flatten(
		crosses.map((c) => {
			const questionCrosses = question.answers.map((answer) => {
				return {
					...c,
					answers: [
						...c.answers,
						pick(answer, ['id', 'translations', 'value']),
					],
					amount: Math.floor(
						c.amount * (answer.value / group.amountOfQuestionnaires)
					),
				};
			});

			const rest =
				c.amount -
				questionCrosses.reduce((acc, cross) => acc + cross.amount, 0);

			for (let idx = 0; idx < questionCrosses.length; idx++) {
				const cross = questionCrosses[idx];
				cross.amount = cross.amount + (idx < rest ? 1 : 0);
			}

			return questionCrosses;
		})
	);
};

export const appendFeasibilityData = (
	feasibilityData: Feasibility[],
	crossing: Cross[]
): Cross[] => {
	const hashedData = feasibilityData.map((fd) => ({
		cross: omit(fd, 'feasibility'),
		feasibility: fd.feasibility,
		id: hash(
			{
				ageFrom: fd.ageFrom,
				ageTo: fd.ageTo,
				gender: fd.gender,
				answers: fd.answers.map((a) => a.id),
				province: fd.province,
				county: fd.county,
				city: fd.city,
				postalCode: fd.postalCode,
			},
			{
				algorithm: 'md5',
			}
		),
	}));

	return crossing.map((c) => ({
		...c,
		feasibility: hashedData.find((fd) => fd.id === c.id)?.feasibility || 0,
	}));
};

interface PartialSpan {
	ageFrom: number;
	ageTo: number;
}

interface CalculatedSpan extends PartialSpan {
	id: string;
	value: number;
}

export interface RepresentativeResult {
	calcGenders: {
		male: number;
		female: number;
	};
	calcSpans: CalculatedSpan[];
	calcRegions?: {
		id: string;
		value: number;
	}[];
}

export function getAveragedRepresentativeData(
	n: number,
	spans: PartialSpan[],
	regions: Region[],
	gender = Gender.BOTH
): RepresentativeResult {
	const ageFrom = spans[0].ageFrom;
	const ageTo = spans[spans.length - 1].ageTo;

	let calcSpans = [],
		i = ageFrom;

	while (i < ageTo) {
		const t = i;
		const nextSpan = spans.find(
			(sp) => sp.ageFrom <= t && sp.ageTo >= t
		) as PartialSpan;
		const from = nextSpan.ageFrom < t ? t : nextSpan.ageFrom;
		const to = nextSpan.ageTo > ageTo ? ageTo : nextSpan.ageTo;
		calcSpans.push({ ageFrom: from, ageTo: to });
		i = to + 1;
	}

	const mappedStats = GUSData.ageGenderStats.map((s) =>
		gender === Gender.BOTH
			? s
			: {
					...s,
					all:
						gender === Gender.FEMALE
							? s.female
							: gender === Gender.MALE
								? s.male
								: s.all,
					male: gender === Gender.MALE ? s.male : 0,
					female: gender === Gender.FEMALE ? s.female : 0,
				}
	);

	const totals = mappedStats
		.filter((s) => s.age >= ageFrom && s.age <= ageTo)
		.reduce(
			(acc, s) => {
				return {
					all: acc.all + s.all,
					male: acc.male + s.male,
					female: acc.female + s.female,
				};
			},
			{
				all: 0,
				male: 0,
				female: 0,
			}
		);

	calcSpans = calcSpans
		.map((cs) => {
			return mappedStats
				.filter((s) => s.age >= cs.ageFrom && s.age <= cs.ageTo)
				.reduce(
					(acc, s) => {
						return {
							...acc,
							value: acc.value + s.all,
						};
					},
					{
						ageFrom: cs.ageFrom,
						ageTo: cs.ageTo,
						value: 0,
					}
				);
		})
		.map((cs) => {
			return {
				...cs,
				value: cs.value / totals.all,
			};
		})
		.map((cs) => {
			return {
				...cs,
				value: Math.floor(n * cs.value),
			};
		});

	const spansTotal = calcSpans.reduce((acc, cs) => acc + cs.value, 0);

	calcSpans = calcSpans.map((cs, idx) => {
		const value = cs.value + (idx < n % spansTotal ? 1 : 0);

		return {
			...cs,
			id: crypto.randomUUID(),
			value,
		};
	});

	const nMale = Math.floor((totals.male * n) / totals.all);
	const nFemale = Math.floor((totals.female * n) / totals.all);

	const calcGenders = {
		male: nMale + (n - nMale - nFemale),
		female: nFemale,
	};

	if (regions.length > 1) {
		let includeRegions = checkIfRegionsAreOfSameType(regions);

		if (!!regions[0].city)
			includeRegions = regions.every((reg) => {
				return (
					reg.province &&
					reg.city &&
					GUSData.regionStats[reg.province].city[reg.city]
				);
			});

		if (!includeRegions)
			return {
				calcGenders,
				calcSpans,
			};

		const regionsWithValues = regions.map((reg) => {
			if (reg.city)
				return {
					id: reg.regionId,
					value:
						GUSData.regionStats[reg.province as string].city[
							reg.city as string
						],
				};
			if (reg.county) {
				return {
					id: reg.regionId,
					value:
						GUSData.regionStats[reg.province as string].county[
							reg.county.split(' ').slice(1).join(' ')
						],
				};
			}
			return {
				id: reg.regionId,
				value: GUSData.regionStats[reg.province as string].amount,
			};
		});

		const total = regionsWithValues.reduce((acc, reg) => acc + reg.value, 0);

		const calcRegions = regionsWithValues.map((reg) => {
			const value = Math.floor(n * (reg.value / total));
			return { id: reg.id, value };
		});

		let rest = n - calcRegions.reduce((acc, reg) => acc + reg.value, 0);

		for (const reg of calcRegions) {
			if (rest) {
				reg.value++;
				rest--;
			}
		}

		return {
			calcGenders,
			calcSpans,
			calcRegions,
		};
	}

	return {
		calcGenders,
		calcSpans,
	};
}

export function getRepresentativeDataFromCrossing(
	group: ResearchGroup,
	data: GusRegionStats
): RepresentativeResult {
	const crossing = generateCrossingFromData(group, data, true);

	const calcGenders = {
		male: 0,
		female: 0,
	};

	const calcSpans = GUSData.ageSpans.map((span) => ({
		...span,
		id: crypto.randomUUID(),
		value: 0,
	}));

	const calcRegionsSignature: StringSignature<number> = {};

	for (const regionId in data) {
		calcRegionsSignature[regionId] = 0;
	}

	for (const cross of crossing) {
		cross.gender === Gender.MALE
			? (calcGenders.male += cross.amount)
			: (calcGenders.female += cross.amount);

		const regionId = hash(pick(cross, ['province', 'county', 'city']), {
			algorithm: 'md5',
		});
		calcRegionsSignature[regionId] += cross.amount;

		const span = calcSpans.find((sp) => sp.ageFrom === cross.ageFrom);
		if (!span) continue;

		span.value += cross.amount;
	}

	const calcRegions = Object.entries(calcRegionsSignature).map(
		([id, value]) => ({
			id,
			value,
		})
	);

	return { calcGenders, calcSpans, calcRegions };
}

const urlReg =
	/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
const idReg = /\[ID\]/;

export function validateResearchUrl(url?: string, omitId = false) {
	if (!url) return false;
	if (omitId)
		return (
			url.length > APP_CONFIG.QUESTIONNAIRE_LINK_MIN_LENGTH && url.length < 256
		);

	const urlRegex = new RegExp(urlReg);
	const idRegex = new RegExp(idReg);
	return urlRegex.test(url) && idRegex.test(url);
}

export function countFeasibilityForCondition(
	crossing: Cross[],
	condition: (cross: Cross) => boolean
) {
	return crossing.reduce(
		(acc, cross) => (condition(cross) ? acc + (cross.feasibility ?? 0) : acc),
		0
	);
}

export function createRegionLabel(
	{ province, county, city }: Partial<Region>,
	cityOnly = false
) {
	if (city)
		return cityOnly ? city : `${city}, ${province?.toLocaleLowerCase()}`;
	if (county) return county;
	if (province) return province;
	return 'Incorrect region name!';
}

export function mapQuestionnaireStatusName(
	status: QuestionnaireStatus,
	getText: (slug: string) => string
) {
	switch (status) {
		case QuestionnaireStatus.PENDING:
			return getText('panelist_questionnaireStatus_awaiting');
		case QuestionnaireStatus.COMPLETED:
			return getText('panelist_questionnaireStatus_finished');
		case QuestionnaireStatus.QUOTAFULL:
			return getText('panelist_questionnaireStatus_QF');
		case QuestionnaireStatus.SCREENOUT:
			return getText('panelist_questionnaireStatus_SO');
		case QuestionnaireStatus.DROPOUT:
			return getText('panelist_questionnaireStatus_dropout');
		case QuestionnaireStatus.TIMEOUT:
			return getText('panelist_questionnaireStatus_timeout');
		case QuestionnaireStatus.EXCLUDED:
			return getText('panelist_questionnaireStatus_excluded');
		case QuestionnaireStatus.EXPIRED:
			return getText('panelist_questionnaireStatus_expired');
		case QuestionnaireStatus.QUAL:
			return getText('panelist_questionnaireStatus_qual');
		default:
			return 'Invalid questonnaire status!';
	}
}

export function compareCrosses(
	cross: Cross,
	userCross: Cross,
	crossSwitches: CrossSwitches
) {
	let toOmit: (keyof Cross)[] = ['id', 'answers', 'amount', 'feasibility'];
	if (!crossSwitches.gender) toOmit.push('gender');
	if (!crossSwitches.region) {
		toOmit = [...toOmit, 'province', 'county', 'city', 'postalCode'];
	}
	if (!crossSwitches.age) {
		toOmit = [...toOmit, 'ageFrom', 'ageTo'];
	}
	const isPartialEqual = isEqual(omit(cross, toOmit), omit(userCross, toOmit));
	if (!isPartialEqual) return false;
	if (!userCross.answers.length) return true;
	return userCross.answers.every((answer) =>
		cross.answers.some((ans) => ans.id === answer.id)
	);
}

export function getProvinceRegions(): Region[] {
	const provinces = [
		'Województwo dolnośląskie',
		'Województwo kujawsko-pomorskie',
		'Województwo lubelskie',
		'Województwo lubuskie',
		'Województwo łódzkie',
		'Województwo małopolskie',
		'Województwo mazowieckie',
		'Województwo opolskie',
		'Województwo podkarpackie',
		'Województwo podlaskie',
		'Województwo pomorskie',
		'Województwo świętokrzyskie',
		'Województwo warmińsko-mazurskie',
		'Województwo wielkopolskie',
		'Województwo śląskie',
		'Województwo zachodniopomorskie',
	];

	return provinces
		.map((p) => ({ province: p, county: null, city: null }))
		.map((p) => ({ ...p, regionId: hash(p, { algorithm: 'md5' }) }));
}

export function calculateRegionQuantity(
	amount: number,
	quantityRegion: ResearchGroup['quantity']['region']
): ResearchGroup['quantity']['region'] {
	return {
		...quantityRegion,
		regions: quantityRegion.regions.map((region, index, array) => ({
			...region,
			value:
				Math.floor(amount / array.length) +
				(index < amount % array.length ? 1 : 0),
		})),
		postalCodes: quantityRegion.postalCodes.map((code, index, array) => ({
			...code,
			value:
				Math.floor(amount / array.length) +
				(index < amount % array.length ? 1 : 0),
		})),
	};
}

export function getRegionType(region: Region) {
	if (region.city) return 'city';
	if (region.county) return 'county';
	return 'province';
}

export function checkIfRegionsAreOfSameType(regions: Region[]) {
	const mappedRegionsTypes = regions.map(getRegionType);

	return mappedRegionsTypes.every((type) => type === mappedRegionsTypes[0]);
}

export function mapResearchStatusToName(
	getText: (slug: string) => string,
	status?: ResearchStatus
): string {
	switch (status) {
		case ResearchStatus.DRAFT:
			return getText('client_researchStatus_draft');
		case ResearchStatus.ACCEPTED:
			return getText('client_researchStatus_accepted');
		case ResearchStatus.TESTING:
			return getText('client_researchStatus_testing');
		case ResearchStatus.SOFT_LAUNCH:
			return getText('client_researchStatus_softLaunch');
		case ResearchStatus.FULL_LAUNCH:
			return getText('client_researchStatus_fullLaunch');
		case ResearchStatus.FINISHED:
			return getText('client_researchStatus_finished');
		default:
			return '-';
	}
}

export function getAgeLabel(from: number, to: number, sufix: string) {
	return from !== to ? `${from} ${sufix} - ${to} ${sufix}` : `${from} ${sufix}`;
}

export function countCrossSwitches(switches: CrossSwitches) {
	return Object.values(switches).reduce((acc, s) => acc + Number(s), 0);
}

export function countCrossing(group: ResearchGroup) {
	const gender =
		group.gender === Gender.BOTH && !group.quantity.gender.isAuto ? 2 : 1;
	const regions =
		(!group.quantity.region.isAuto && group.regionsChosen.length) || 1;
	const ageSpans =
		(!group.quantity.age.isAuto && group.quantity.age.spans.length) || 1;
	const postalCodes =
		(!group.quantity.region.isAuto && group.postalCodesChosen.length) || 1;

	let sum = gender * ageSpans * regions * postalCodes;

	for (const question of group.questionsChosen) {
		sum = sum * question.answers.length;
	}

	return sum;
}

export function isResearchGroupCorrect(
	group: ResearchGroup,
	status: ResearchStatus
) {
	if (group.isInvitedsLoaded) return true;

	if (!group.isNew && !!status) return true;

	if (!group.crossing.length) return false;

	if (
		group.gender === Gender.BOTH &&
		group.quantity.gender.female + group.quantity.gender.male >
			group.amountOfQuestionnaires
	)
		return false;

	if (
		group.regionSwitch === LocalizationTypes.REGION &&
		group.quantity.region.regions.reduce((acc, curr) => acc + curr.value, 0) >
			group.amountOfQuestionnaires
	)
		return false;

	if (
		group.regionSwitch === LocalizationTypes.POSTAL_CODE &&
		group.quantity.region.postalCodes.reduce(
			(acc, curr) => acc + curr.value,
			0
		) > group.amountOfQuestionnaires
	)
		return false;

	if (
		!group.quantity.age.spans.length ||
		group.quantity.age.spans[group.quantity.age.spans.length - 1].span[1] !==
			group.ageSpan[1]
	)
		return false;

	if (
		group.quantity.age.spans.reduce((acc, curr) => acc + curr.value, 0) >
		group.amountOfQuestionnaires
	)
		return false;

	for (const question of group.questionsChosen) {
		const answersSum = question.answers.reduce(
			(acc, curr) => acc + curr.value,
			0
		);
		if (answersSum !== group.amountOfQuestionnaires) return false;
	}
	if (countCrossSwitches(group.crossSwitches) > 1) {
		const reducedUserCrossesValues = group.userCrosses.reduce(
			(acc, curr) => acc + curr.amount,
			0
		);
		return reducedUserCrossesValues === group.amountOfQuestionnaires;
	}
	return true;
}

export function sortRegionsByName(a: Region, b: Region) {
	const firstName = clone(a.city || a.county || a.province || '');
	const secondName = clone(b.city || b.county || b.province || '');

	return firstName.localeCompare(secondName);
}

export function prepareResearchSample(
	researchGroups: ResearchGroup[],
	language: Language,
	getText: ContentContext['getText']
): ExportResearchSamplePayload {
	const payload: ExportResearchSamplePayload = {};

	for (const group of researchGroups) {
		payload[group.name] = {
			[getText('client_targetGroup_crossing_togglerGender')]: {
				[getText('gender_male')]: group.quantity.gender.male,
				[getText('gender_female')]: group.quantity.gender.female,
			},
			[getText('client_targetGroup_quantity_headerAge')]:
				group.quantity.age.spans.reduce((acc, curr) => {
					Object.assign(acc, {
						[getAgeLabel(
							curr.span[0],
							curr.span[1],
							getText('agePluralSufix')
						)]: curr.value,
					});

					return acc;
				}, {}),
			[getText('client_targetGroup_crossing_togglerRegion')]:
				group.quantity.region.regions.reduce((acc, curr) => {
					Object.assign(acc, {
						[createRegionLabel(curr.region)]: curr.value,
					});

					return acc;
				}, {}),
			[getText('client_register_postalCodeLabel')]:
				group.quantity.region.postalCodes.reduce((acc, curr) => {
					Object.assign(acc, {
						[curr.code]: curr.value,
					});

					return acc;
				}, {}),
		};

		for (const question of group.questionsChosen) {
			payload[group.name][question.translations[language].content] =
				question.answers.reduce((acc, curr) => {
					Object.assign(acc, {
						[curr.translations[language].content]: curr.value,
					});

					return acc;
				}, {});
		}
	}

	for (const groupName in payload) {
		const group = payload[groupName];

		for (const type in group) {
			const element = group[type];
			if (isEmpty(element)) delete payload[groupName][type];
		}
	}

	return payload;
}

export function prepareNewResearchGroupPayload(
	group: ResearchGroup,
	fullService: boolean
) {
	const groupPayload = {
		...omit(group, ['isNew', 'id']),
		questionnaireUrl: group.questionnaireUrl.trim(),
		testQuestionnaireUrl: group.testQuestionnaireUrl.trim(),
		panelistRegistrationDateFrom: group.panelistRegistrationDateFrom
			? formatDateForInput(group.panelistRegistrationDateFrom)
			: undefined,
		crossing: group.isInvitedsLoaded
			? []
			: group.crossing
					.filter((cross) => !!cross.amount)
					.map((cross) => ({
						...cross,
						answers: cross.answers.map((answer) => answer.id),
					})),
	};

	if (fullService) {
		groupPayload.questionnaireUrl = '';
		groupPayload.testQuestionnaireUrl = '';
		groupPayload.uniqueLinks = [];
	}

	return groupPayload;
}

import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { Notification } from '@/components/partials/notification/Notification';
import { LoadingOverlay } from '@mantine/core';
import { PasswordInput } from '@/components/partials/passwordInput/PasswordInput';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { Role } from '@/types/user';
import {
	roleToContentPath,
	roleToAppPath,
	validatePassword,
} from '@/utils/utilities';
import { ErrorCodes, useContent } from '@/providers/ContentProvider';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import LogoNPB from '@/assets/images/logo.svg';
import styles from './SetNewPassword.module.scss';

const initialValues = {
	password: '',
	repeatPassword: '',
};

interface Props {
	role: Role;
}

export const SetNewPassword: React.FC<Props> = ({ role }) => {
	const { getText, getContent, getErrorMessage } = useContent();

	const baseSlug = `${roleToContentPath(role)}_setPassword_`;

	const navigate = useNavigate();

	const { token } = useParams();

	useEffect(() => {
		if (!token) navigate(`/${roleToAppPath(role)}/login`);
	}, []);

	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues,
		validate: {
			password: (value: string, values: Partial<typeof initialValues>) => {
				if (value !== values.repeatPassword)
					return getText(`${baseSlug}badPassword`);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
			repeatPassword: (
				value: string,
				values: Partial<typeof initialValues>
			) => {
				if (value !== values.password) return getText(`${baseSlug}badPassword`);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setLoading(true);
		const response = await UnauthenticatedApi.resetPassword(
			token || '',
			form.values.password
		);
		setLoading(false);
		form.reset();

		if (response?.status < 400) return setSuccess(true);

		setErrorMessage(getErrorMessage(response?.data?.insideExceptionCode));
	};

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{!!errorMessage && (
				<Notification theme="error" content={<p>{errorMessage}</p>} />
			)}
			{success ? (
				<Notification
					theme="informative"
					content={getContent(`${baseSlug}information`, {
						$login: `/${roleToAppPath(role)}/login`,
					})}
				/>
			) : (
				<>
					<PasswordInput
						{...form.getInputProps('password')}
						label={getText(`${baseSlug}passwordLabel`)}
						placeholder={getText(`${baseSlug}passwordPlaceholder`)}
						mb={24}
					/>
					<PasswordInput
						{...form.getInputProps('repeatPassword')}
						label={getText(`${baseSlug}repeatPasswordLabel`)}
						placeholder={getText(`${baseSlug}repeatPasswordPlaceholder`)}
						mb={32}
					/>

					<Button
						themes={['blue', 'arrow']}
						buttonProps={{
							type: 'submit',
						}}
					>
						<p>{getText(`${baseSlug}setButton`)}</p>
					</Button>
					{getContent(`${baseSlug}information`, {
						$login: `/${roleToAppPath(role)}/login`,
					})}
				</>
			)}
		</form>
	);
};

import { configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import researchReducer, {
	researchListenerMiddleware,
} from './slices/researchSlice';

export const store = configureStore({
	reducer: {
		app: appReducer,
		research: researchReducer,
	},
	devTools: import.meta.env.DEV,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActionPaths: ['research.researchGroups'],
			},
		}).prepend(researchListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import LANGUAGES from '@/configs/languages';

export interface StringSignature<TData = any> {
	[key: string]: TData;
}

export type Language = keyof typeof LANGUAGES;

export type Content = Record<Language, string>;

export type SortOrder = 'asc' | 'desc';

export interface Sort {
	key: string | null;
	order: SortOrder;
}

export interface HydraQueryOptions {
	activePage: number;
	sort?: Sort;
	sizePerPage?: number;
}
export interface HydraResponse<TData extends {} = {}> {
	'hydra:member': TData[];
	'hydra:totalItems': number;
}

export interface HydraFormattedResponseData<TData extends {} = {}> {
	data: TData[];
	total: number;
}

export enum InputActions {
	INCREMENT = 'increment',
	DECREMENT = 'decrement',
}

export enum Gender {
	MALE,
	FEMALE,
	BOTH,
}

export interface Attachment {
	id: string;
	originalName: string;
	size: string;
	contentUrl: string;
	mimeType: string;
}

export type postalCode = { first: string; second: string };

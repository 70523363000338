import React, { CSSProperties } from 'react';
import Icons from '@/assets/Icons';
import styles from './Notification.module.scss';

type NotificationTheme = 'informative' | 'success' | 'warning' | 'error';

interface Props {
	content: string | JSX.Element | JSX.Element[];
	theme: NotificationTheme;
	icon?: JSX.Element;
	noIcon?: boolean;
	styles?: CSSProperties;
}

export const Notification: React.FC<Props> = (props) => {
	return (
		<div
			className={`${styles.container} ${returnThemeClass(props.theme)} fade-in`}
			style={props.styles}
		>
			{!props.noIcon && (
				<div className="decor decor-no-shadow">
					{props.icon || returnIconSrc(props.theme)}
				</div>
			)}
			{props.content}
		</div>
	);
};

function returnThemeClass(theme: NotificationTheme) {
	switch (theme) {
		case 'informative':
			return styles.containerInformative;
		case 'success':
			return styles.containerSuccess;
		case 'warning':
			return styles.containerWarning;
		case 'error':
			return styles.containerError;
	}
}

function returnIconSrc(theme: NotificationTheme) {
	switch (theme) {
		case 'informative':
			return Icons.info;
		case 'warning':
			return Icons.info;
		case 'success':
			return Icons.checked;
		case 'error':
			return Icons.cancel;
	}
}

import { QueryFunctionContext } from '@tanstack/react-query';
import { User, Panelist, Client, Role, Pollster } from '@/types/user';
import { roleToApiPath } from '@/utils/utilities';
import { QueryKey } from '@/configs/queryKeys';
import { pick } from 'lodash';
import httpClient from './clients/httpClient';

export interface GlobalSettings {
	algorithm: string;
	cpi: number;
	yearlyManagementFee: number;
	pointsConversionRate: number;
	payoutThreshold: number;
	notificationsFrequency: number;
	defaultLoi: number;
	defaultDuration: number;
	scoringRules: [];
	defaultAdmin: string;
	maxAffiliationsPerPanelist: number;
	pointsForAffiliation: number;
	pointsForAffiliatedPanelist: number;
	maxAffiliationsPerClient: number;
	discountForAffiliation: number;
	uniqueLinksMultiplayer: number;
}

abstract class AuthenticatedApi {
	static getSettings = async ({ queryKey }: QueryFunctionContext<[string]>) => {
		const response = await httpClient.get<GlobalSettings[]>(queryKey[0]);
		return response.data[0];
	};

	static changeEmail = async ({
		email,
		password,
		user,
	}: {
		email: string;
		password: string;
		user: User;
	}) => {
		const roleUrl = roleToApiPath(user.role);

		const response = await httpClient.patch<Panelist | Client>(
			`${roleUrl}/${user.id}/change_email`,
			{ email, current_password: password }
		);

		return response.data;
	};

	static changePassword = async ({
		password: current_password,
		newPassword: password,
		user,
	}: {
		password: string;
		newPassword: string;
		user: User;
	}) => {
		const roleUrl = roleToApiPath(user.role);

		const response = await httpClient.patch<Panelist | Client>(
			`${roleUrl}/${user.id}/change_password`,
			{ password, current_password }
		);

		return response.data;
	};

	static edit = async (user: Partial<Panelist | Client>) => {
		const roleUrl = roleToApiPath(user.role!);

		const response = await httpClient.put<Panelist | Client>(
			`${roleUrl}/${user.id}/edit`,
			user
		);

		return response.data;
	};

	static changeReferralCode = async (payload: Partial<Panelist | Client>) => {
		if (payload.role === Role.PANELIST) {
			const response = await httpClient.patch<Panelist>(
				`${QueryKey.panelists.panelists}/${payload.id}/change_referral`,
				pick(payload, ['referralCode'])
			);

			return response.data;
		}

		const response = await httpClient.patch<Client>(
			`${QueryKey.clients}/${payload.id}/change_referral`,
			pick(payload, ['referralCode'])
		);

		return response.data;
	};

	static getCurrentUser = async (id: string, role: Role) => {
		switch (role) {
			case Role.PANELIST: {
				const response = await httpClient.get<Panelist>(
					`${QueryKey.panelists.panelists}/${id}`
				);
				return response.data;
			}
			case Role.CLIENT: {
				const response = await httpClient.get<Client>(
					`${QueryKey.clients}/${id}`
				);
				return response.data;
			}
			case Role.POLLSTER: {
				const response = await httpClient.get<Pollster>(
					`${QueryKey.pollsters}/${id}`
				);
				return response.data;
			}
		}
	};
}
export default AuthenticatedApi;

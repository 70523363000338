import React, { useContext, ReactNode, useLayoutEffect, useState } from 'react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Role, User } from '@/types/user';
import { LoginValues } from '@/components/views/unauthenticated/login/ClientLoginForm';
import { useQueryClient } from '@tanstack/react-query';
import { PollsterLoginAttempt } from '@/api/PanelistApi';
import { useMatch, useNavigate } from 'react-router-dom';
import { roleToAppPath } from '@/utils/utilities';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import AuthenticatedApi from '@/api/AuthenticatedApi';
import httpClient from '@/api/clients/httpClient';
import Cookies from 'js-cookie';

export enum StorageKeys {
	REFRESH_TOKEN = 'refresh_token',
}

interface TokenPayload extends JwtPayload {
	id: string;
	roles: Role[];
}

interface Context {
	user: User | null;
	changeUser: (newUser?: User | null) => void;
	updateUser: (payload: Partial<User>) => void;
	login: (values: LoginValues, role: Role) => Promise<number>;
	loginPollster: (values: LoginValues) => Promise<PollsterLoginAttempt | null>;
	logout: () => void;
	handleCompletedLogin: (
		token: string,
		refreshToken: string
	) => Promise<boolean>;
	redirectOnLogUrl: string;
	setRedirectOnLogUrl: (val: string | ((prevState: string) => string)) => void;
}

const AuthContext = React.createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const isAdminLogOnClient = useMatch('/admin-log-on-client');
	const [redirectOnLogUrl, setRedirectOnLogUrl] = useSessionStorage<string>({
		key: 'redirectUrl',
		defaultValue: '',
	});

	const [user, setUser] = useLocalStorage<User | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [refreshToken, setRefreshToken, clearRefreshToken] = useSessionStorage<
		string | undefined
	>({
		key: StorageKeys.REFRESH_TOKEN,
		defaultValue: Cookies.get(StorageKeys.REFRESH_TOKEN),
		getInitialValueInEffect: false,
	});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	const [appResume, setAppResume] = useState(0);
	useLayoutEffect(() => {
		if (!Capacitor.isNativePlatform()) return;

		SecureStoragePlugin.keys().then((keys) => {
			if (keys.value.includes(StorageKeys.REFRESH_TOKEN))
				SecureStoragePlugin.get({ key: StorageKeys.REFRESH_TOKEN }).then(
					({ value }) => {
						loginWithResfreshToken(value);
					}
				);
			else loginWithResfreshToken();
		});
	}, [appResume]);

	useLayoutEffect(() => {
		if (isAdminLogOnClient) return;

		if (Capacitor.isNativePlatform()) {
			App.addListener('resume', () => {
				setAppResume((prev) => prev + 1);
			});
		} else loginWithResfreshToken(refreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			setUser(null);
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			const remember = !!Cookies.get(StorageKeys.REFRESH_TOKEN);

			await setAuth(decoded, token, refresh_token, remember);
		} catch (error: any) {
			console.error(error);
			if (error.message !== 'Request aborted') {
				setUser(null);
				eradicateRefreshToken();
			}
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload, refresh: string) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = window.setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async (
		{ email, password, remember },
		role
	) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			if (response.data.insideExceptionCode)
				return response.data.insideExceptionCode as number;

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			if (role !== decoded.roles[1]) return 1;

			await setAuth(decoded, token, refresh_token, remember);

			return 0;
		} catch (error) {
			console.error(error);
			return 1;
		}
	};

	const loginPollster: Context['loginPollster'] = async ({
		email,
		password,
	}) => {
		try {
			const response = await UnauthenticatedApi.loginPollster({
				email,
				password,
			});

			if (response.status >= 400) return null;
			return response.data;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const handleCompletedLogin: Context['handleCompletedLogin'] = async (
		token,
		refreshToken
	) => {
		const decoded = jwtDecode(token) as TokenPayload;

		if (!decoded) return false;

		await setAuth(decoded, token, refreshToken);

		return true;
	};

	const logout = () => {
		queryClient.cancelQueries();
		eradicateRefreshToken();
		httpClient.defaults.headers.common['Authorization'] = '';
		setUser(null);
		queryClient.clear();
		clearTimeout(refreshTimeout);
	};

	const eradicateRefreshToken = () => {
		clearRefreshToken();

		if (Capacitor.isNativePlatform())
			SecureStoragePlugin.remove({ key: StorageKeys.REFRESH_TOKEN });
		else Cookies.remove(StorageKeys.REFRESH_TOKEN);
	};

	const saveRefreshToken = (refreshToken: string, isPollser = false) => {
		setRefreshToken(refreshToken);

		if (Capacitor.isNativePlatform())
			SecureStoragePlugin.set({
				key: StorageKeys.REFRESH_TOKEN,
				value: refreshToken,
			});
		else
			Cookies.set(StorageKeys.REFRESH_TOKEN, refreshToken, {
				secure: true,
				expires: isPollser ? 1 : 360,
			});
	};

	const setAuth = async (
		decoded: TokenPayload,
		token: string,
		refresh: string,
		remember = true
	) => {
		refreshTokenWhenExpire(decoded, refresh);

		setAuthHeader(token);

		const role = decoded.roles[1];

		const user = await AuthenticatedApi.getCurrentUser(decoded.id, role);

		user.role = role;

		if (remember) saveRefreshToken(refresh, role === Role.POLLSTER);
		else setRefreshToken(refresh);

		setUser(user);

		if (
			redirectOnLogUrl &&
			roleToAppPath(user.role) === redirectOnLogUrl.split('/')[0]
		)
			navigate(redirectOnLogUrl);
	};

	const changeUser: Context['changeUser'] = (newUser: User | null = null) =>
		setUser(newUser);

	const updateUser: Context['updateUser'] = (payload) =>
		setUser((prev) => ({ ...prev!, ...(payload as User) }));

	return (
		<AuthContext.Provider
			value={{
				user,
				changeUser,
				updateUser,
				login,
				loginPollster,
				logout,
				handleCompletedLogin,
				redirectOnLogUrl,
				setRedirectOnLogUrl,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

import { ResearchStatus } from '@/types/research';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	EDIT_LOGOUT_TIME: 6,
	RESEARCH_GROUP_MAX_NAME_LENGTH: 25,
	QUESTIONNAIRE_LINK_MIN_LENGTH: 8,
	RESEARCH_GROUP_INITIAL_STARTS_AT_HOUR: 9,
	RESEARCH_GROUP_INITIAL_AMOUNT: 1000,
	RESEARCH_GROUP_MIN_AMOUNT: 10,
	RESEARCH_GROUP_MAX_AMOUNT: 99_999,
	RESEARCH_GROUP_MIN_LOI: 1,
	RESEARCH_GROUP_MAX_LOI: 50,
	RESEARCH_GROUP_DEFAULT_LOI: 10,
	RESEARCH_GROUP_DEFAULT_DURATION: 5,
	RESEARCH_MAX_CROSSINGS: 25_000,
	RESEARCH_MAX_FEASIBILITY: 3000,
	RESEARCH_GENERATE_CROSSING_DEBOUNCE: 500,
	RESEARCH_MAX_ATTACHMENTS: 10,
	RESEARCH_MAX_AGE_SPANS_LENGTH: 10,
	RESEARCH_FULL_LAUNCH_DEFAULT_QUESTIONNAIRES_SEND_MULTIPLIER: 3.5,
	RESEARCH_UNIQUE_LINK_DEFAULT_QUANTITY_MULTIPLIER: 15,
	RESEARCH_BOOSTABLE_STATUSES: [ResearchStatus.FINISHED] as ResearchStatus[],
	MAX_PASSWORD_LENGTH: 18,
	MIN_AGE: 18,
	MAX_AGE: 99,
	GROUP_INITIAL_AGE_START: 18,
	GROUP_INITIAL_AGE_END: 99,
	SIZE_PER_PAGE: 6,
	TEXTAREA_LIMIT: 200,
	FEEDBACK_CONTENT_MIN: 5,
	FEEDBACK_CONTENT_LIMIT: 500,
	INFINITE_SCROLL_PER_PAGE: 5,
	BREAKPOINTS: {
		MOBILE: 550,
		TABLET: 900,
	},
	Z_INDEX: {
		OVERLAY: 50,
		SCROLL_TOP_BTN: 50,
		NAVBAR: 301,
		MODAL: 302,
	},
	REFERRAL_CODE_LENGTH: {
		MIN: 5,
		MAX: 20,
	},
	DEFAULT_LANGUAGE: 'PL',
	META_TITLE: {
		GENERIC: 'Panel badawczy NPB',
		PANELIST: 'Panel badawczy NPB - Wypełniaj ankiety i zdobywaj punkty!',
		CLIENT:
			'NPB - Twórz projekty badawcze, poznawaj opnie oraz doświadczenia respondentów',
		POLLSTER: 'NPB - Przeprowadzaj wywiady telefoniczne z respondentami',
	},
	META_DESCRIPTION: {
		GENERIC: 'Panel badawczy NPB',
		PANELIST:
			'Aplikacja dla panelistów NPB, zbieraj punkty i wymieniaj na gotówkę.',
		CLIENT:
			'NPB - Twórz projekty badawcze, poznawaj opnie oraz doświadczenia respondentów',
		POLLSTER: 'NPB - Przeprowadzaj wywiady telefoniczne z respondentami',
	},
	GENDER_QUESITON_ID: '1ed08d70-8bcd-6136-8e99-b7834744ef9f',
	FOOTER_HEIGHT: 70,
	CAPACITOR_BROWSER_TOOLBAR_COLOR: '#e2e8f0',
	PLAY_STORE_URL:
		'https://play.google.com/store/apps/details?id=pl.com.npb.aplikacja',
} as const;

export default APP_CONFIG;

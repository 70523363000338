import moment from 'moment';

const expires = moment().add(3, 'days').toDate();

const PlaceholderQuestionnaires = [
	{
		name: 'Opinie konsumentów',
		description:
			'Jak oceniasz jakość zakupionych produktów? Podziel się swoją opinią.',
		points: 30,
		expires,
		loi: 7,
	},
	{
		name: 'Ulubione seriale',
		description:
			'Jakie seriale oglądasz najchętniej? Wypełnij ankietę o swoich preferencjach.',
		points: 20,
		expires,
		loi: 5,
	},
	{
		name: 'Eko świadomość',
		description:
			'Czy stosujesz się do zasad ekologii? Opowiedz nam o swoich nawykach.',
		points: 40,
		expires,
		loi: 10,
	},
	{
		name: 'Nowe technologie',
		description:
			'Interesują Cię nowinki technologiczne? Podziel się swoimi opiniami.',
		points: 25,
		expires,
		loi: 8,
	},
	{
		name: 'Praca zdalna',
		description:
			'Jak pracuje Ci się zdalnie? Wypełnij ankietę na temat pracy z domu.',
		points: 35,
		expires,
		loi: 12,
	},
	{
		name: 'Zdrowe odżywianie',
		description:
			'Czy Twoja dieta jest zdrowa? Podziel się swoimi doświadczeniami z dietą.',
		points: 20,
		expires,
		loi: 6,
	},
	{
		name: 'Moda miejska',
		description: 'Co myślisz o obecnych trendach w modzie? Wyraź swoje zdanie.',
		points: 30,
		expires,
		loi: 9,
	},
	{
		name: 'Kawa czy herbata',
		description:
			'Preferujesz kawę czy herbatę? Wypełnij krótką ankietę o swoich preferencjach.',
		points: 25,
		expires,
		loi: 4,
	},
	{
		name: 'Sport w życiu',
		description: 'Jaką rolę odgrywa sport w Twoim życiu? Opowiedz nam więcej.',
		points: 35,
		expires,
		loi: 11,
	},
	{
		name: 'Edukacja online',
		description:
			'Czy korzystałeś z kursów online? Podziel się swoimi doświadczeniami.',
		points: 20,
		expires,
		loi: 7,
	},
	{
		name: 'Książki elektroniczne',
		description:
			'Czy preferujesz e-booki czy tradycyjne książki? Wyraź swoje zdanie.',
		points: 30,
		expires,
		loi: 6,
	},
	{
		name: 'Streaming muzyki',
		description:
			'Jakie platformy do streamingu muzyki preferujesz? Podziel się opiniami.',
		points: 25,
		expires,
		loi: 5,
	},
	{
		name: 'Podróże zagraniczne',
		description:
			'Jakie są Twoje ulubione kierunki podróży? Opowiedz o swoich doświadczeniach.',
		points: 40,
		expires,
		loi: 12,
	},
	{
		name: 'Rekrutacja testerów',
		description:
			'Chcesz testować nowe produkty? Zgłoś się do naszej bazy testerów.',
		points: 20,
		expires,
		loi: 3,
	},
	{
		name: 'Opieka zdrowotna',
		description:
			'Jak oceniasz dostęp do usług zdrowotnych? Podziel się swoją opinią.',
		points: 30,
		expires,
		loi: 10,
	},
	{
		name: 'Gry wideo',
		description:
			'Jakie gry wideo lubisz najbardziej? Wypełnij ankietę o swoich preferencjach.',
		points: 25,
		expires,
		loi: 8,
	},
	{
		name: 'Aplikacje mobilne',
		description:
			'Jakie aplikacje na smartfony używasz najczęściej? Podziel się swoimi ulubionymi.',
		points: 20,
		expires,
		loi: 6,
	},
	{
		name: 'Samochody elektryczne',
		description:
			'Interesujesz się samochodami elektrycznymi? Wyraź swoją opinię na ten temat.',
		points: 35,
		expires,
		loi: 9,
	},
	{
		name: 'Kino domowe',
		description:
			'Preferujesz oglądanie filmów w kinie czy w domu? Opowiedz nam o swoich preferencjach.',
		points: 25,
		expires,
		loi: 5,
	},
	{
		name: 'Kultura kawiarniana',
		description:
			'Czy lubisz spędzać czas w kawiarniach? Opowiedz nam o swoich doświadczeniach.',
		points: 25,
		expires,
		loi: 4,
	},
	{
		name: 'Rower czy samochód',
		description:
			'Jakie środki transportu preferujesz na co dzień? Podziel się swoimi wyborami.',
		points: 30,
		expires,
		loi: 8,
	},
	{
		name: 'Filmy dokumentalne',
		description:
			'Lubię oglądać filmy dokumentalne. A Ty? Podziel się swoimi ulubionymi.',
		points: 20,
		expires,
		loi: 5,
	},
	{
		name: 'Przyjęcia domowe',
		description:
			'Czy często organizujesz przyjęcia domowe? Opowiedz nam o swoich doświadczeniach.',
		points: 35,
		expires,
		loi: 10,
	},
	{
		name: 'Fast food',
		description:
			'Jak często jesz fast food? Wypełnij ankietę o swoich nawykach żywieniowych.',
		points: 20,
		expires,
		loi: 4,
	},
	{
		name: 'Social media',
		description:
			'Które platformy social media lubisz najbardziej? Podziel się swoimi ulubionymi.',
		points: 25,
		expires,
		loi: 7,
	},
	{
		name: 'Festiwale muzyczne',
		description:
			'Czy uczestniczysz w festiwalach muzycznych? Opowiedz o swoich doświadczeniach.',
		points: 30,
		expires,
		loi: 9,
	},
	{
		name: 'Zwierzęta domowe',
		description:
			'Jakie zwierzęta domowe masz? Podziel się historiami o swoich pupilach.',
		points: 20,
		expires,
		loi: 6,
	},
	{
		name: 'Fitness i siłownia',
		description:
			'Czy regularnie ćwiczysz na siłowni? Opowiedz nam o swoich rutynach treningowych.',
		points: 35,
		expires,
		loi: 11,
	},
	{
		name: 'Języki obce',
		description:
			'Jakie języki obce znasz? Podziel się swoimi doświadczeniami w nauce języków.',
		points: 25,
		expires,
		loi: 8,
	},
	{
		name: 'Fotografia mobilna',
		description:
			'Czy robisz zdjęcia smartfonem? Opowiedz o swojej pasji do fotografii mobilnej.',
		points: 20,
		expires,
		loi: 5,
	},
	{
		name: 'Wolontariat',
		description:
			'Czy kiedykolwiek byłeś wolontariuszem? Podziel się swoimi doświadczeniami.',
		points: 30,
		expires,
		loi: 9,
	},
	{
		name: 'Ekologiczne opakowania',
		description:
			'Jak ważne są dla Ciebie ekologiczne opakowania? Wypełnij ankietę o Twoich preferencjach.',
		points: 25,
		expires,
		loi: 7,
	},
	{
		name: 'Ogródki działkowe',
		description:
			'Czy posiadasz ogródek działkowy? Opowiedz o swoich doświadczeniach z ogrodnictwem.',
		points: 20,
		expires,
		loi: 6,
	},
	{
		name: 'Książki dla dzieci',
		description:
			'Jakie książki dla dzieci polecasz? Podziel się swoimi rekomendacjami.',
		points: 25,
		expires,
		loi: 5,
	},
	{
		name: 'Diety wegańskie',
		description:
			'Jakie są Twoje doświadczenia z dietą wegańską? Wypełnij ankietę o swoich nawykach.',
		points: 30,
		expires,
		loi: 10,
	},
	{
		name: 'Gospodarka odpadami',
		description:
			'Jak segregujesz odpady? Podziel się swoimi praktykami w zakresie recyklingu.',
		points: 20,
		expires,
		loi: 7,
	},
	{
		name: 'Mieszkania studenckie',
		description:
			'Jakie są Twoje doświadczenia z mieszkaniami studenckimi? Opowiedz nam o swoim życiu studenckim.',
		points: 35,
		expires,
		loi: 12,
	},
	{
		name: 'Kawa z ekspresu',
		description:
			'Czy preferujesz kawę z ekspresu? Opowiedz nam o swoich kawowych przyzwyczajeniach.',
		points: 25,
		expires,
		loi: 5,
	},
	{
		name: 'Zakupy online',
		description:
			'Jak często robisz zakupy przez internet? Opisz swoje doświadczenia.',
		points: 20,
		expires,
		loi: 6,
	},
	{
		name: 'Hulajnoga Elektryczna',
		description: 'Jeździsz na hulajnodze elektrycznej? Wyraź swoją opinię',
		points: 25,
		expires,
		loi: 7,
	},
];

export type PlaceholderQuestionnaire =
	(typeof PlaceholderQuestionnaires)[number];

export default PlaceholderQuestionnaires;

import React, { useContext, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@mantine/hooks';
import { Content, Language, StringSignature } from '@/types/types';
import { Loading } from '@/components/partials/loader/Loading';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { getFileSrc } from '@/utils/utilities';
import parse, { Element, domToReact } from 'html-react-parser';
import httpClient from '@/api/clients/httpClient';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/de';

export interface AppError {
	insideMessage: string;
	insideExceptionCode: number;
	message: string;
	code: string;
}

export enum ErrorCodes {
	USER_NOT_FOUND = 100,
	INVALID_EMAIL = 101,
	DUPLICATED_EMAIL = 102,
	INVALID_PASSWORD = 103,
	INVALID_CURRENT_PASSWORD = 104,
	INACTIVE_USER = 105,
	POLLSTER_NOT_FOUND = 109,
	USER_NOT_VERIFIED = 116,
	INVALID_URL = 130,
	INVALID_PESEL = 134,
	INVALID_NIP = 135,
	ACCOUNT_SUSPENDED = 136,
	PAYOUT_MISSING_DATA = 137,
	FORBIDDEN_DOMAIN = 141,
	MISSING_REFERRAL = 142,
	INVALID_REFERRAL = 143,
	REFERRAL_LIMIT_REACHED = 144,
	MISSING_REGIONS = 160,
	CONTEST_FAILED = 161,
}

const errorCodesSlug = 'errorCodes_';

export interface ContentContext {
	getText: (
		slug: string,
		vars?: StringSignature<string | number | undefined>
	) => string;
	getContent: (
		slug: string,
		links?: StringSignature,
		vars?: StringSignature<string | number>
	) => string | JSX.Element | JSX.Element[];
	getImage: (slug: string) => string;
	language: Language;
	setLanguage: (lang: Language) => void;
	getErrorMessage: (code?: ErrorCodes) => string;
}

const ContentContext = React.createContext<ContentContext>(null!);

export const useContent = () => useContext(ContentContext);

const loadContent = async () => {
	const response = await httpClient.get<StringSignature<Content>>(
		'/content.json',
		{
			baseURL: 'https://aplikacja.npb.com.pl/',
		}
	);

	return response.data;
};

export const ContentProvider = ({ children }: { children: ReactNode }) => {
	const [content, setContent] = useLocalStorage<StringSignature<Content>>({
		key: 'content',
		defaultValue: {},
		getInitialValueInEffect: false,
	});

	const [language, setLanguage] = useLocalStorage<Language>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});
	const [_, rerender] = useState(false);

	useEffect(() => {
		moment.locale(language === 'GB' ? 'en-gb' : language.toLowerCase());
		document.documentElement.setAttribute(
			'lang',
			language === 'GB' ? 'en' : language.toLowerCase()
		);
		rerender((prev) => !prev);
	}, [language]);

	const contentQuery = useQuery({
		queryKey: ['content'],
		queryFn: loadContent,
		staleTime: 1000 * 60 * 5,
		meta: {
			persistLoading: true,
			ignoreErrors: true,
		},
	});

	useEffect(() => {
		if (!contentQuery.data || contentQuery.isError) return;

		setContent(contentQuery.data);
	}, [contentQuery.data]);

	const getText: ContentContext['getText'] = (slug, vars = {}) => {
		if (!content[slug]) return slug;

		let text = content[slug][language];

		if (!text) return slug;

		for (const [variable, value = ''] of Object.entries(vars)) {
			text = text.replaceAll(variable, value.toString());
		}

		return text;
	};

	const getContent: ContentContext['getContent'] = (slug, links, vars = {}) => {
		if (!content[slug] || !content[slug][language]) return slug;

		let raw = content[slug][language];

		for (const [variable, value] of Object.entries(vars)) {
			raw = raw.replaceAll(variable, value.toString());
		}

		return parse(raw, {
			replace: (domNode) => {
				const node = domNode as Element;
				if (node.name === 'a' && links && links[node.attribs.href]) {
					return (
						<Link to={links[node.attribs.href]}>
							{domToReact(node.children)}
						</Link>
					);
				}
			},
		});
	};

	const getImage: ContentContext['getImage'] = (slug: string) => {
		if (!content[slug] || !content[slug][language]) return slug;
		return getFileSrc(content[slug][language]);
	};

	const getErrorMessage: ContentContext['getErrorMessage'] = (
		code?: ErrorCodes
	) => {
		switch (code) {
			case ErrorCodes.USER_NOT_FOUND:
				return 'Taki email nie istnieje w systemie';
			case ErrorCodes.INVALID_EMAIL:
				return 'Podany adres email jest nieprawidłowy!';
			case ErrorCodes.DUPLICATED_EMAIL:
				return 'Podany adres email został już wykorzystany';
			case ErrorCodes.INVALID_PASSWORD:
				return 'Hasło musi mieć co najmniej 8 znaków, małą i wielką literę, cyfrę oraz znak specjalny';
			case ErrorCodes.INVALID_CURRENT_PASSWORD:
				return getText(`${errorCodesSlug}wrongPassword`);
			case ErrorCodes.INACTIVE_USER:
				return 'Konto jest nieaktywne';
			case ErrorCodes.POLLSTER_NOT_FOUND:
				return 'Nie odnaleziono takiego ankietera!';
			case ErrorCodes.USER_NOT_VERIFIED:
				return 'Użytknownik nie został jeszcze aktytowany!';
			case ErrorCodes.INVALID_PESEL:
				return 'Podany nr pesel jest niepoprawny!';
			case ErrorCodes.INVALID_NIP:
				return 'Podany NIP jest niepoprawny!';
			case ErrorCodes.ACCOUNT_SUSPENDED:
				return 'Twoje konto jest zawieszone!';
			case ErrorCodes.PAYOUT_MISSING_DATA:
				return 'Brak wymaganych informacji!';
			case ErrorCodes.MISSING_REGIONS:
				return getText(`${errorCodesSlug}missingRegions`);
			case ErrorCodes.FORBIDDEN_DOMAIN:
				return getText(`${errorCodesSlug}forbiddenDomain`);
			case ErrorCodes.MISSING_REFERRAL:
				return 'Nie wysłano kodu polecającego!';
			case ErrorCodes.INVALID_REFERRAL:
				return 'Nie odnalziono kodu polecającego!';
			case ErrorCodes.REFERRAL_LIMIT_REACHED:
				return 'Limit poleceń dla tego kodu został wyczerpany!';
			case ErrorCodes.CONTEST_FAILED:
				return 'Nie osiągnięto wymaganej liczby poprawnych odpowiedzi! Spróbuj ponownie.';

			default:
				return 'Coś poszło nie tak! Spróbuj ponownie później';
		}
	};

	return (
		<ContentContext.Provider
			value={{
				getText,
				getContent,
				getImage,
				language,
				setLanguage,
				getErrorMessage,
			}}
		>
			{isEmpty(content) ? <Loading fullHeight /> : children}
		</ContentContext.Provider>
	);
};

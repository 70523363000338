import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { PasswordInput } from '@/components/partials/passwordInput/PasswordInput';
import { Notification } from '@/components/partials/notification/Notification';
import { useForm, isEmail } from '@mantine/form';
import { LoadingOverlay, TextInput, Tooltip } from '@mantine/core';
import { omit } from 'lodash';
import { Address, Role } from '@/types/user';
import { AxiosResponse } from 'axios';
import { ErrorCodes, useContent } from '@/providers/ContentProvider';
import { useDebouncedValue } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@/configs/queryKeys';
import {
	roleToContentPath,
	roleToAppPath,
	validatePassword,
	validatePhoneNumber,
} from '@/utils/utilities';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import classNames from 'classnames';
import APP_CONFIG from '@/configs/appConfig';
import LogoNPB from '@/assets/images/logo.svg';
import Icons from '@/assets/Icons';
import styles from './RegisterForm.module.scss';

export interface RegisterValues extends Address {
	name: string;
	lastName: string;
	company?: string;
	NIP?: string;
	email: string;
	phone: string;
	password: string;
	repeatPassword: string;
	terms: boolean;
	privacy: boolean;
}

interface Props {
	role: Role;
}

export const RegisterForm: React.FC<Props> = ({ role }) => {
	const { getText, getContent, getErrorMessage } = useContent();

	const baseSlug = `${roleToContentPath(role)}_register_`;

	const [errorMessage, setErrorMessage] = useState('');
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const isClient = role === 'ROLE_CLIENT';
	const isPanelist = role === 'ROLE_PANELIST';

	const affiliationSettingsQuery = useQuery({
		queryKey: [QueryKey.globalSettingsReferral],
		queryFn: UnauthenticatedApi.getReferralSettings,
	});

	const form = useForm({
		initialValues: initialValuesPerRole(role),
		validate: {
			phone: (value: string) =>
				value && !validatePhoneNumber(value)
					? getText('feedback_badPhoneNumber')
					: null,
			email: isEmail(getText('feedback_badEmail')),
			password: (value: string, values: Partial<RegisterValues>) => {
				if (value !== values.repeatPassword)
					return getText(`${baseSlug}badPassword`);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
			repeatPassword: (value: string, values: Partial<RegisterValues>) => {
				if (value !== values.password) return getText(`${baseSlug}badPassword`);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCodes.INVALID_PASSWORD);
				return null;
			},
		},
	});
	const [searchParams] = useSearchParams();

	const referralLabel = useMemo(
		() =>
			role === Role.PANELIST
				? `Wprowadź kod otrzymany od polecającego. Po wypełnieniu ankiety podstawowej doliczymy Ci ${affiliationSettingsQuery.data?.pointsForAffiliatedPanelist} pkt a Twojemu znajomemu ${affiliationSettingsQuery.data?.pointsForAffiliation} pkt`
				: 'Wprowadź kod otrzymany od polecającego',
		[role]
	);

	useEffect(() => {
		const referralCode = searchParams.get('referralCode');
		if (referralCode) form.setValues({ referralCode });
	}, []);

	const handleSubmit = async () => {
		setLoading(true);
		form.reset();

		const payload = omit(form.values, ['privacy', 'terms', 'repeatPassword']);
		if (payload.referralCode && referralCodeQuery.isError)
			delete payload.referralCode;

		let response: AxiosResponse | undefined;
		switch (role) {
			case Role.PANELIST:
				response = await UnauthenticatedApi.registerPanelist(payload);
				break;
			case Role.CLIENT:
				response = await UnauthenticatedApi.registerClient(payload);
				break;
		}
		setLoading(false);
		if (response?.status === 201) {
			setSuccess(true);
			setErrorMessage('');
			return;
		}
		setErrorMessage(getErrorMessage(response?.data?.insideExceptionCode));
	};

	const [debouncedReferralCode] = useDebouncedValue(
		form.values.referralCode,
		250
	);

	const referralCodeQuery = useQuery({
		queryKey: ['check_referral', debouncedReferralCode!, role],
		queryFn: UnauthenticatedApi.checkReferralCode,
		retry: false,
		staleTime: 1000 * 60,
		meta: {
			ignoreErrors: true,
		},
		enabled:
			!!debouncedReferralCode &&
			debouncedReferralCode.length >= APP_CONFIG.REFERRAL_CODE_LENGTH.MIN,
	});

	const formContent = (
		<>
			{isClient && (
				<>
					<div className={styles.inputRow}>
						<div>
							<label htmlFor="firstName">
								{getText(`${baseSlug}firstNameLabel`)}
							</label>
							<input
								{...form.getInputProps('firstName')}
								required
								id="firstName"
								placeholder={getText(`${baseSlug}firstNamePlaceholder`)}
								maxLength={30}
							/>
						</div>
						<div>
							<label htmlFor="lastName">
								{getText(`${baseSlug}lastNameLabel`)}
							</label>
							<input
								{...form.getInputProps('lastName')}
								required
								id="lastName"
								placeholder={getText(`${baseSlug}lastNamePlaceholder`)}
								maxLength={60}
							/>
						</div>
					</div>
					<div className={styles.inputRow}>
						<div>
							<label htmlFor="email">{getText(`${baseSlug}emailLabel`)}</label>
							<TextInput
								{...form.getInputProps('email')}
								required
								id="email"
								placeholder={getText(`${baseSlug}emailPlaceholder`)}
								type="email"
							/>
						</div>
						<div>
							<label htmlFor="phone">{getText(`${baseSlug}phoneLabel`)}</label>
							<TextInput
								{...form.getInputProps('phone')}
								required
								id="phone"
								placeholder={getText(`${baseSlug}phonePlaceholder`)}
								type="tel"
							/>
						</div>
					</div>
					<div
						className={classNames(styles.inputRow, styles.inputRowFullWidth)}
					>
						<div>
							<label htmlFor="address">
								{getText(`${baseSlug}addressLabel`)}
							</label>
							<TextInput
								{...form.getInputProps('address')}
								required
								type="text"
								id="address"
								placeholder={getText(`${baseSlug}addressPlaceholder`)}
							/>
						</div>
					</div>
					<div className={styles.inputRow}>
						<div>
							<label htmlFor="postalCode">
								{getText(`${baseSlug}postalCodeLabel`)}
							</label>
							<TextInput
								{...form.getInputProps('postalCode')}
								required
								id="postalCode"
								placeholder={getText(`${baseSlug}postalCodePlaceholder`)}
								type="text"
							/>
						</div>
						<div>
							<label htmlFor="city">{getText(`${baseSlug}cityLabel`)}</label>
							<TextInput
								{...form.getInputProps('city')}
								required
								type="text"
								id="city"
								placeholder={getText(`${baseSlug}cityPlaceholder`)}
							/>
						</div>
					</div>
					<div className={styles.inputRow}>
						<div>
							<label htmlFor="companyName">
								{getText(`${baseSlug}companyLabel`)}
							</label>
							<input
								{...form.getInputProps('companyName')}
								required
								id="companyName"
								placeholder={getText(`${baseSlug}companyPlaceholder`)}
								maxLength={60}
							/>
						</div>
						<div>
							<label htmlFor="vatNumber">
								{getText(`${baseSlug}vatLabel`)}
							</label>
							<TextInput
								{...form.getInputProps('vatNumber')}
								required
								id="vatNumber"
								placeholder={getText(`${baseSlug}vatPlaceholder`)}
								inputMode="numeric"
								minLength={8}
								maxLength={12}
							/>
						</div>
					</div>
				</>
			)}
			{isPanelist && (
				<div
					className={classNames(styles.inputRow, styles.inputRowFullWidth)}
					style={{ width: '100%' }}
				>
					<div>
						<label htmlFor="email">Adres e-mail</label>
						<input
							{...form.getInputProps('email')}
							required
							id="email"
							placeholder="Wpisz adres"
							type="email"
						/>
					</div>
				</div>
			)}
			<div className={styles.inputRow}>
				<div>
					<PasswordInput
						{...form.getInputProps('password')}
						label={getText(`${baseSlug}passwordLabel`)}
						placeholder={getText(`${baseSlug}passwordPlaceholder`)}
					/>
				</div>
				<div>
					<PasswordInput
						{...form.getInputProps('repeatPassword')}
						label={getText(`${baseSlug}repeatPasswordLabel`)}
						placeholder={getText(`${baseSlug}repeatPasswordPlaceholder`)}
					/>
				</div>
			</div>
			<div className={styles.inputRow} style={{ width: '100%' }}>
				<div>
					<label htmlFor="referralCode" className={styles.labelTooltip}>
						Kod poleceń{' '}
						<Tooltip multiline label={referralLabel} withArrow width={300}>
							{Icons.info}
						</Tooltip>
					</label>
					<TextInput
						{...form.getInputProps('referralCode')}
						id="referralCode"
						placeholder="Wpisz kod poleceń"
						type="text"
						minLength={APP_CONFIG.REFERRAL_CODE_LENGTH.MIN}
						maxLength={APP_CONFIG.REFERRAL_CODE_LENGTH.MAX}
						error={
							!!referralCodeQuery.error &&
							getErrorMessage(
								Number((referralCodeQuery.error as Error).message)
							)
						}
					/>
				</div>
			</div>
			<div className={`checkbox-container ${styles.checkbox}`}>
				<div className="input-container">
					<input
						required
						id="terms"
						type="checkbox"
						{...form.getInputProps('terms', { type: 'checkbox' })}
					/>
				</div>
				<label className="checkbox-label" htmlFor="terms">
					{getContent(`${baseSlug}checkboxLabelTerms`)}
				</label>
			</div>
			<div className={`checkbox-container ${styles.checkbox}`}>
				<div className="input-container">
					<input
						required
						id="privacy"
						type="checkbox"
						{...form.getInputProps('privacy', { type: 'checkbox' })}
					/>
				</div>
				<label className="checkbox-label" htmlFor="privacy">
					{getContent(`${baseSlug}checkboxLabelPrivacy`)}
				</label>
			</div>
			<Button
				themes={['blue', 'arrow']}
				buttonProps={{
					type: 'submit',
				}}
			>
				<p>{getText(`${baseSlug}buttonSubmit`)}</p>
			</Button>
		</>
	);

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{!!errorMessage && (
				<Notification
					theme="error"
					content={<p>{errorMessage}</p>}
					icon={Icons.mail}
				/>
			)}
			{!success && referralCodeQuery.isSuccess && (
				<Notification
					theme="success"
					content={<p>Podano prawidłowy kod poleceń!</p>}
				/>
			)}
			{success ? (
				role === Role.CLIENT ? (
					<Notification
						theme="informative"
						content={<p>{getText(`${baseSlug}messageSuccess`)}</p>}
						icon={Icons.mail}
					/>
				) : (
					<Notification
						theme="success"
						content={<p>{getText(`${baseSlug}messageSuccess`)}</p>}
						icon={Icons.mail}
					/>
				)
			) : (
				formContent
			)}
			{getContent(`${baseSlug}information`, {
				$login: `/${roleToAppPath(role)}/login`,
			})}
		</form>
	);
};

function initialValuesPerRole(role: Role) {
	switch (role) {
		case Role.CLIENT:
			return {
				firstName: '',
				lastName: '',
				companyName: '',
				vatNumber: '',
				postalCode: '',
				address: '',
				city: '',
				email: '',
				phone: '',
				password: '',
				repeatPassword: '',
				referralCode: '',
				terms: false,
				privacy: false,
			};
		case Role.PANELIST:
			return {
				email: '',
				password: '',
				repeatPassword: '',
				referralCode: '',
				terms: false,
				privacy: false,
			};
		default:
			return {
				name: '',
				lastName: '',
				email: '',
				password: '',
				phone: '',
				repeatPassword: '',
				terms: false,
				privacy: false,
			};
	}
}
